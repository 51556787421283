import { createSlice } from '@reduxjs/toolkit'
import { NflGame } from '../sdk/model'

const initialState: NflGame[] = []

const nflGameDataLoadSlice = createSlice({
  name: 'nflGameDataLoad',
  initialState,
  reducers: {
    setNflGameDataLoad (state, action) {
      console.log(action)
      return state = action.payload
      // return state = {...state, ...action.payload}
    }
  }
})

export const { setNflGameDataLoad } = nflGameDataLoadSlice.actions
export default nflGameDataLoadSlice.reducer