/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LeagueEvent } from '../model';
// @ts-ignore
import { PlayerSelection } from '../model';
/**
 * LeagueEventApi - axios parameter creator
 * @export
 */
export const LeagueEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoPick: async (leagueEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('autoPick', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}/autoPick`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league event
         * @param {LeagueEvent} [leagueEvent] A new &#x60;league_event&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeagueEvent: async (leagueEvent?: LeagueEvent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/league_events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leagueEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing `league_event`.
         * @summary Delete a league_event
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeagueEvent: async (leagueEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('deleteLeagueEvent', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueEvent: async (leagueEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('getLeagueEvent', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueId 
         * @param {number} [itemsLoaded] The number of items already loaded
         * @param {number} [seasonYear] the year for which to pull events
         * @param {number} [week] the week for which to pull events
         * @param {string} [type] the type events to return
         * @param {boolean} [fullDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueEvents: async (leagueId: string, itemsLoaded?: number, seasonYear?: number, week?: number, type?: string, fullDetails?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getLeagueEvents', 'leagueId', leagueId)
            const localVarPath = `/league_events/byLeague/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (itemsLoaded !== undefined) {
                localVarQueryParameter['itemsLoaded'] = itemsLoaded;
            }

            if (seasonYear !== undefined) {
                localVarQueryParameter['seasonYear'] = seasonYear;
            }

            if (week !== undefined) {
                localVarQueryParameter['week'] = week;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (fullDetails !== undefined) {
                localVarQueryParameter['fullDetails'] = fullDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {PlayerSelection} [playerSelection] Details about the player selection during a &#x60;league_event&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeSelection: async (leagueEventId: string, playerSelection?: PlayerSelection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('makeSelection', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}/makeSelection`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playerSelection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseEvent: async (leagueEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('pauseEvent', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}/pause`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEvent: async (leagueEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('resetEvent', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}/reset`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeEvent: async (leagueEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('resumeEvent', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}/resume`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewindEvent: async (leagueEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('rewindEvent', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}/rewind`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league event
         * @param {string} leagueEventId 
         * @param {LeagueEvent} [leagueEvent] A &#x60;league_event&#x60; to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeagueEvent: async (leagueEventId: string, leagueEvent?: LeagueEvent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('updateLeagueEvent', 'leagueEventId', leagueEventId)
            const localVarPath = `/league_events/{leagueEventId}`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leagueEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeagueEventApi - functional programming interface
 * @export
 */
export const LeagueEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeagueEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoPick(leagueEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoPick(leagueEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league event
         * @param {LeagueEvent} [leagueEvent] A new &#x60;league_event&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLeagueEvent(leagueEvent?: LeagueEvent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLeagueEvent(leagueEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing `league_event`.
         * @summary Delete a league_event
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeagueEvent(leagueEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeagueEvent(leagueEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeagueEvent(leagueEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeagueEvent(leagueEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueId 
         * @param {number} [itemsLoaded] The number of items already loaded
         * @param {number} [seasonYear] the year for which to pull events
         * @param {number} [week] the week for which to pull events
         * @param {string} [type] the type events to return
         * @param {boolean} [fullDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeagueEvents(leagueId: string, itemsLoaded?: number, seasonYear?: number, week?: number, type?: string, fullDetails?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeagueEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeagueEvents(leagueId, itemsLoaded, seasonYear, week, type, fullDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {PlayerSelection} [playerSelection] Details about the player selection during a &#x60;league_event&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeSelection(leagueEventId: string, playerSelection?: PlayerSelection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeSelection(leagueEventId, playerSelection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseEvent(leagueEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseEvent(leagueEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetEvent(leagueEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetEvent(leagueEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeEvent(leagueEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeEvent(leagueEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewindEvent(leagueEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewindEvent(leagueEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league event
         * @param {string} leagueEventId 
         * @param {LeagueEvent} [leagueEvent] A &#x60;league_event&#x60; to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLeagueEvent(leagueEventId: string, leagueEvent?: LeagueEvent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLeagueEvent(leagueEventId, leagueEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeagueEventApi - factory interface
 * @export
 */
export const LeagueEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeagueEventApiFp(configuration)
    return {
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoPick(leagueEventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.autoPick(leagueEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league event
         * @param {LeagueEvent} [leagueEvent] A new &#x60;league_event&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeagueEvent(leagueEvent?: LeagueEvent, options?: any): AxiosPromise<string> {
            return localVarFp.createLeagueEvent(leagueEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing `league_event`.
         * @summary Delete a league_event
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeagueEvent(leagueEventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeagueEvent(leagueEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueEvent(leagueEventId: string, options?: any): AxiosPromise<LeagueEvent> {
            return localVarFp.getLeagueEvent(leagueEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueId 
         * @param {number} [itemsLoaded] The number of items already loaded
         * @param {number} [seasonYear] the year for which to pull events
         * @param {number} [week] the week for which to pull events
         * @param {string} [type] the type events to return
         * @param {boolean} [fullDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueEvents(leagueId: string, itemsLoaded?: number, seasonYear?: number, week?: number, type?: string, fullDetails?: boolean, options?: any): AxiosPromise<Array<LeagueEvent>> {
            return localVarFp.getLeagueEvents(leagueId, itemsLoaded, seasonYear, week, type, fullDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {PlayerSelection} [playerSelection] Details about the player selection during a &#x60;league_event&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeSelection(leagueEventId: string, playerSelection?: PlayerSelection, options?: any): AxiosPromise<void> {
            return localVarFp.makeSelection(leagueEventId, playerSelection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseEvent(leagueEventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.pauseEvent(leagueEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEvent(leagueEventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetEvent(leagueEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeEvent(leagueEventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resumeEvent(leagueEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewindEvent(leagueEventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.rewindEvent(leagueEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league event
         * @param {string} leagueEventId 
         * @param {LeagueEvent} [leagueEvent] A &#x60;league_event&#x60; to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeagueEvent(leagueEventId: string, leagueEvent?: LeagueEvent, options?: any): AxiosPromise<void> {
            return localVarFp.updateLeagueEvent(leagueEventId, leagueEvent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeagueEventApi - object-oriented interface
 * @export
 * @class LeagueEventApi
 * @extends {BaseAPI}
 */
export class LeagueEventApi extends BaseAPI {
    /**
     * 
     * @param {string} leagueEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public autoPick(leagueEventId: string, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).autoPick(leagueEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new instance of a `league`.
     * @summary Create a league event
     * @param {LeagueEvent} [leagueEvent] A new &#x60;league_event&#x60; to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public createLeagueEvent(leagueEvent?: LeagueEvent, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).createLeagueEvent(leagueEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing `league_event`.
     * @summary Delete a league_event
     * @param {string} leagueEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public deleteLeagueEvent(leagueEventId: string, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).deleteLeagueEvent(leagueEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public getLeagueEvent(leagueEventId: string, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).getLeagueEvent(leagueEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueId 
     * @param {number} [itemsLoaded] The number of items already loaded
     * @param {number} [seasonYear] the year for which to pull events
     * @param {number} [week] the week for which to pull events
     * @param {string} [type] the type events to return
     * @param {boolean} [fullDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public getLeagueEvents(leagueId: string, itemsLoaded?: number, seasonYear?: number, week?: number, type?: string, fullDetails?: boolean, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).getLeagueEvents(leagueId, itemsLoaded, seasonYear, week, type, fullDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueEventId 
     * @param {PlayerSelection} [playerSelection] Details about the player selection during a &#x60;league_event&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public makeSelection(leagueEventId: string, playerSelection?: PlayerSelection, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).makeSelection(leagueEventId, playerSelection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public pauseEvent(leagueEventId: string, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).pauseEvent(leagueEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public resetEvent(leagueEventId: string, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).resetEvent(leagueEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public resumeEvent(leagueEventId: string, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).resumeEvent(leagueEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public rewindEvent(leagueEventId: string, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).rewindEvent(leagueEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new instance of a `league`.
     * @summary Create a league event
     * @param {string} leagueEventId 
     * @param {LeagueEvent} [leagueEvent] A &#x60;league_event&#x60; to be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventApi
     */
    public updateLeagueEvent(leagueEventId: string, leagueEvent?: LeagueEvent, options?: AxiosRequestConfig) {
        return LeagueEventApiFp(this.configuration).updateLeagueEvent(leagueEventId, leagueEvent, options).then((request) => request(this.axios, this.basePath));
    }
}
