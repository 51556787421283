import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League, LeagueEvent, Team } from '../../../sdk/model';
import {Box, Typography} from '@mui/material';

import { leagueEventApi } from '../../../adapters/APIExporter';
import WaiverFaabEventFullView from './WaiverFaabEventFullView';
import QuickSignDisplay from '../leagueEvent/QuickSignDisplay';

function PlayerAcquisitionEventView() {
  const {leagueId, eventId} = useParams();
  
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [event, setEvent] = React.useState<LeagueEvent | undefined>(undefined);
  
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, 
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  React.useEffect(() => {
    leagueEventApi.getLeagueEvent(eventId).then(response => setEvent(response.data))
  }, []);
  
  if (!isLoadComplete || !event) {
    return <p>Loading...</p>
  }
  
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  
  const eventTypeViewMap : Map<string, Function> = new Map([
    ['waiver_faab_event', () => <WaiverFaabEventFullView league={league!} playerMap={playerMap} teamMap={teamMap} event={event} />],
    ['player_quick_sign_event', () => <Box>
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Free Agent Quick-Sign:
      </Typography>
      <QuickSignDisplay playerMap={playerMap} teamMap={teamMap} event={event} />
    </Box>]
  ]);
  
  return eventTypeViewMap.get(event.type)!.apply(this);
}

export default PlayerAcquisitionEventView;
