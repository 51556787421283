import * as React from 'react';
import { ContractExtension, Player, Team } from '../../../sdk/model';
import {Typography} from '@mui/material';
import TeamDisplay from '../team/TeamDisplay';
import PlayerCardLink from '../../cards/playerCardLink';
import {toSalary} from '../../util/SalaryDisplay';

interface practiceSquadActivationDisplayProps {
  leagueId: string,
  teamId: string,
  playerId: string | undefined,
  amount: number,
  playerMap: Map<String | undefined, Player | undefined> | undefined,
  teamMap: Map<String | undefined, Team>
}

function PracticeSquadActivationDisplay(props: practiceSquadActivationDisplayProps) {
  const team = props.teamMap.get(props.teamId)!;
  const player = props.playerMap ? props.playerMap.get(props.playerId) : undefined;
  return <Typography>
    <TeamDisplay leagueId={props.leagueId} team={team} emphasized={false} />
    activated {player !== undefined && <PlayerCardLink player={player} leagueId={props.leagueId} />}
    from the Practice Squad for 1yr/{toSalary(props.amount)}
  </Typography>
}

export default PracticeSquadActivationDisplay;
