import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {Box} from '@mui/material';
import { AuctionPick, Team } from '../../../sdk/model';
import { Button } from '@mui/material';
import {getNumberOrdinal} from '../../util/DraftPickDisplay'
import { toSalary } from '../../util/SalaryDisplay';
import TeamDisplay from '../team/TeamDisplay';

interface auctionPickSelectionDisplayProps {
  pick: AuctionPick,
  teamMap: Map<string | undefined, Team>
}

function AuctionPickSelectionDisplay(props: auctionPickSelectionDisplayProps) {
  const navigate = useNavigate();
  
  const pickDateTime = new Date(props.pick.updatedTimeStamp);
  const auctionYear = pickDateTime.getFullYear();
  return <Box>
    <p>Signed by <TeamDisplay leagueId={props.pick.leagueId} team={props.teamMap.get(props.pick.teamId)!} emphasized={false} /> as the {getNumberOrdinal(props.pick.pickNumber)} signee of the
    <Button onClick={() => navigate('/league/' + props.pick.leagueId + '/auctionHistory?seasonYear=' + auctionYear)}>{auctionYear} Free Agent Auction</Button>
    (Nominated by <TeamDisplay leagueId={props.pick.leagueId} team={props.teamMap.get(props.pick.nominatingTeamId)!} emphasized={false} />)</p>
    {props.pick.topTeamBids.length > 1 ?
      <Box>
        <p>Also bid on by</p>
        {props.pick.topTeamBids.filter(bid => bid.teamId !== props.pick.teamId).map(bid => 
          <p><b>{props.teamMap.get(bid.teamId)!.name}:</b> {toSalary(bid.totalAmount)}/{bid.years}yr{bid.years > 1 && "s"}</p>
        )}
      </Box> : <p>No other bidding teams</p>
    }
  </Box>
}

export default AuctionPickSelectionDisplay;
