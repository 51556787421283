import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { leagueEventApi } from '../../../adapters/APIExporter';
import AuctionBoard from './component/AuctionBoard';
import { Team } from '../../../sdk/model';
import Box from '@mui/material/Box';
import { Select, MenuItem, Typography } from '@mui/material';

function AuctionHistory({league}) {
  const [searchParams] = useSearchParams();
  const defaultSeasonYear = searchParams.get("seasonYear");
  const [seasonYear, setSeasonYear] = React.useState(defaultSeasonYear ? defaultSeasonYear : league.currentSeasonYear);
  const [auction, setAuction] = React.useState(undefined);
  const [loadingAuction, setLoadingAuction] = React.useState(true);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  
  React.useEffect(() => {
    leagueEventApi.getLeagueEvents(league.id, undefined, seasonYear, undefined, 'auction', true).then(response => {
      if (response.data.length) {
        setAuction(response.data[0])
      }
      setLoadingAuction(false);
    });
  }, [seasonYear])
  
  if (loadingAuction) {
    return <p>Loading...</p>
  }
  
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  const teamsInNominationOrder = auction ? auction.draftOrder[0].map((teamId) => teamMap.get(teamId)) : [];
  
  return <Box>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Auction History
    </Typography>
    <Select name="seasonYear" label="Season" labelId="seasonYearLabel" value={seasonYear}
        onChange={event => setSeasonYear(event.target.value)}>
      {[...Array(league.totalSeasons).keys()]
        .map(seasonIndex => league.currentSeasonYear - league.totalSeasons + seasonIndex + 1)
        .map(seasonYearOption => <MenuItem key={seasonYearOption} value={seasonYearOption}>{seasonYearOption}</MenuItem>)}
    </Select>
    <br/>
    {auction ? <AuctionBoard auction={auction} playerMap={playerMap} teams={teamsInNominationOrder} /> : <p>Auction data not found</p>}
  </Box>
}

export default AuctionHistory;
