import * as React from 'react';
import { League, Player, Team, Contract, TagType, TradeProposalPlayer, TradeProposalPlayerActionEnum } from '../../../sdk/model';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData, useUpdateLeagueData } from '../../../app/dataLoaderHooks';
import {Dialog, DialogTitle, Box, IconButton, DialogContent, Typography, DialogActions, Button} from '@mui/material';
import Close from "@mui/icons-material/Close";
import { toSalary } from '../../util/SalaryDisplay';
import TradeDetails from '../trade/TradeDetails';
import { contractApi } from '../../../adapters/APIExporter';
import { getMaxTeamSize } from '../../util/TeamUtils';

interface rookieOptionTagButtonProps {
  leagueId: string,
  player: Player,
  contract: Contract
  currentSeasonYear: number,
  team: Team,
  callback: Function
}

function RookieOptionTagButton(props: rookieOptionTagButtonProps) {
  const [confirming, setConfirming] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [mutableLeague, setMutableLeague] = React.useState<League | undefined>(undefined);
  
  useUpdateLeagueData(mutableLeague);
  if (mutableLeague) {
    setMutableLeague(undefined);
    props.callback('success', mutableLeague);
  }
  
  const user = useAppSelector((state) => state.user);
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const playerMap = new Map([[props.player.id, props.player]]);
  const teamMap = new Map([[props.team.id, props.team]]);
  const teamPlayerMap = new Map(Object.values(props.team.playerRoster!).flatMap(playerList => 
    playerList.filter(player => player).map(player => [player.playerId, player])
  ));
  const tagValue = league!.currentSeason!.minimumTagValuesAtPosition![props.player!.position!].rookieOption!;
  const weeklyPayments = tagValue / league?.currentSeason?.finalWeek!;
  const maxRosterSize = getMaxTeamSize(league!);
  teamPlayerMap.set(props.player.id, {
    contract: {
      seasons: [{
        season_year: league!.currentSeasonYear! + 1,
        annual_salary: tagValue,
        weeks: [...Array(league?.currentSeason?.finalWeek).keys()].map(weekIndex => weekIndex + 1).map(weekNumber => {
          return {
            fullSalary: weeklyPayments,
            guaranteedSalary: weeklyPayments,
            payments: [{
              teamId: props.team.id,
              weekNumber: weekNumber,
              price: weeklyPayments,
              completed: false
            }]
          }
        })
      }]
    }
  });
  const players : TradeProposalPlayer[] = [{
    playerId: props.player.id,
    toTeamId: props.team.id,
    action: TradeProposalPlayerActionEnum.Trade
  }]
  
  function tagPlayer() {
    contractApi.markPlayerForContractTag(props.leagueId, props.player.id, TagType.RookieOption).then(result => {
      const newLeague = {...league, teams : [...league!.teams]};
      const teamIndex = newLeague.teams.findIndex(team => team.id === result.data.id);
      newLeague.teams[teamIndex] = result.data;
      setMutableLeague(newLeague);
    }).catch(error => {
      props.callback('error', error.response.data.message);
    }).finally(() => {
      setConfirming(false);
    });
  }
  
  return <React.Fragment>
    <Dialog open={confirming} maxWidth="lg" fullWidth>
      <DialogTitle>Activate Rookie Option?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setConfirming(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to designate {props.player.abbr_name} to receive an additional Option Year at the end of his Rookie Contract?  
            This will add an additional one-year, fully-guaranteed season in {props.currentSeasonYear + 1} for {toSalary(tagValue)}.
            Excercising the option will also upgrade the player's {props.currentSeasonYear} salary to be fully-guaranteed as well.
            This option will not be finalized until the league's tag deadline. This will have the following impact on your team's salary cap:
          <TradeDetails leagueId={props.leagueId} teams={[props.team]} currentSeason={props.currentSeasonYear} players={players} picks={[]} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={new Map()} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setConfirming(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={tagPlayer}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Button color="primary" variant="contained" onClick={() => setConfirming(true)}>Tag for Rookie Option Year</Button>
  </React.Fragment>
}

export default RookieOptionTagButton;
