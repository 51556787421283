import React, { useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { leagueApi } from '../../../adapters/APIExporter';
import "../component.css";
import "./style.css";
import { Container, Typography, Grid, Paper, Card, CardHeader, CardContent, CardActionArea, Box, Button} from '@mui/material';
import LeagueQuickView from '../league/LeagueQuickView';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { spacing } from '@mui/system';
import { setLeagueDataLoad } from '../../../slices/leagueDataLoad';
import { useLoadLeagueDataQuickView } from '../../../app/dataLoaderHooks';
import { League } from '../../../sdk/model';

const Home = () => {
  // Navigator/Dispatcher
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // State
  const user = useAppSelector((state) => state.user );
  const [isLoadComplete, setLoadComplete] = useState(false);
  const [isException, setIsException] = React.useState(false);
  var leagues: League[] = useLoadLeagueDataQuickView({userId: user.id, 
                              setLoadComplete: setLoadComplete, setIsException: setIsException});

  // Effects
  const className = 'home';
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={className}
    >
      <Container>
        <Typography variant="h2" align="center" sx={{mt: 3 }}>
           Welcome to CAPSized:
        </Typography>
        <Typography variant="h4" align="center" gutterBottom sx={{mt: 0, mb: 5 }}>
          Your home for contract-based fantasy football
        </Typography>
      </Container>
      <Container className="league-container">
      { user.id &&
        <Grid
          sx={{ margin: "auto" }}
          container
          spacing={2}
          className="league-grid">
        <Grid item xs={12} >
          <Typography variant='h3' align='center' sx={{ margin: "auto" }} >My Leagues</Typography>
        </Grid>
        <LeagueItems leagues={leagues} isLoadComplete={isLoadComplete} isException={isException} user={user}  />
        <Grid container item xs={12} justifyContent="center">
            <Button className='capsized-button' onClick={() => navigate('/createLeague')}>Create New League</Button>
        </Grid>
        </Grid>
      } 
      </Container>
    </Grid>   
  )

}


function LeagueItems({ leagues, isLoadComplete, isException, user }) {
  var leagueItems;
  if(isException) {
    return <Typography color="red">There was an error loading your leagues</Typography>
  }
  else if(!isLoadComplete) {
    return <Typography color="white">Loading league data..</Typography>
  }
  else if(leagues.length === 0) {
    return <Typography color="white">You are not in any leagues</Typography>
  }

  return (
      <React.Fragment>
        {leagues.map((league, index) => 
          <Grid key={index} item xs={12}>
            <LeagueQuickView key={index} userId={user?.id} league={league} />
          </Grid>
        )}
      </React.Fragment>
  );

}



export default Home
