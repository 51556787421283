import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeagueEvent, Player, Team } from '../../../sdk/model';
import { TableRow, Button, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import DraftQuickDisplay from './DraftQuickDisplay';
import AuctionQuickDisplay from './AuctionQuickDisplay';
import TradeAtAGlance from '../trade/TradeAtAGlance';
import WaiverFaabEventQuickView from './WaiverFaabEventQuickView';
import PlayerCardLink from '../../cards/playerCardLink';
import ContractTagDisplay from './ContractTagDisplay';
import QuickSignDisplay from './QuickSignDisplay';
import IrTransactionDisplay from './IrTransactionDisplay';
import MidSeasonExtensionDisplay from './MidSeasonExtensionDisplay';
import PracticeSquadActivationDisplay from './PracticeSquadActivationDisplay';
import TeamDisplay from '../team/TeamDisplay';

interface leagueEventDisplayProps {
  event: LeagueEvent,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team>
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
const EVENT_TYPE_LINK_CREATOR_MAP = new Map();
EVENT_TYPE_LINK_CREATOR_MAP.set('draft', event => '/league/' + event.leagueId + '/draftHistory?seasonYear=' + event.seasonYear)
EVENT_TYPE_LINK_CREATOR_MAP.set('auction', event => '/league/' + event.leagueId + '/auctionHistory?seasonYear=' + event.seasonYear)
EVENT_TYPE_LINK_CREATOR_MAP.set('trade', event => '/league/' + event.leagueId + '/tradeHistory/' + event.id)
EVENT_TYPE_LINK_CREATOR_MAP.set('waiver_faab_event', event => '/league/' + event.leagueId + '/playerAcquisitionEvent/' + event.id)
EVENT_TYPE_LINK_CREATOR_MAP.set('player_quick_sign_event', event => '/league/' + event.leagueId + '/playerAcquisitionEvent/' + event.id)


function getEventTypeDisplay(type) {
  if (type === 'waiver_faab_event') {
    return "Waiver/FAAB Event"
  }
  if (type === 'player_drop_event') {
    return "Player Dropped"
  }
  if (type === 'ir_transaction') {
    return "IR Transaction"
  } if (type === 'mid_season_extension_event') {
    return "Mid-Season Extension"
  } if (type === 'practice_squad_activation_event') {
    return "Practice Squad Activation"
  }
  return type.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}

function LeagueEventDisplay(props: leagueEventDisplayProps) {
  const navigate = useNavigate();
  
  const eventTypeDisplayMap = new Map();
  eventTypeDisplayMap.set('draft', event => <DraftQuickDisplay draft={event} />)
  eventTypeDisplayMap.set('auction', event => <AuctionQuickDisplay auction={event} />)
  eventTypeDisplayMap.set('trade', event => <TradeAtAGlance leagueId={event.leagueId} tradeStatus={event.status} teams={event.tradeProposal.teams} picks={event.tradeProposal.picks} players={event.tradeProposal.players} playerMap={props.playerMap} teamMap={props.teamMap} pickMap={undefined} />)
  eventTypeDisplayMap.set('waiver_faab_event', event => <WaiverFaabEventQuickView event={event} playerMap={props.playerMap} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('player_drop_event', event => {
    const player = props.playerMap.get(event.playerId)!;
    return <Typography>
      <PlayerCardLink player={player} leagueId={event.leagueId} /> was dropped by <TeamDisplay leagueId={props.event.leagueId} team={props.teamMap.get(event.teamId)!} emphasized={false} />
    </Typography>
  })
  eventTypeDisplayMap.set('contract_import', event => 
    <Typography>{event.numberOfImportedContracts} contracts imported</Typography>
  )
  eventTypeDisplayMap.set('contract_tag', event => <ContractTagDisplay contractTag={event} playerMap={props.playerMap} team={props.teamMap.get(event.teamId)!} />)
  eventTypeDisplayMap.set('player_quick_sign_event', event => <QuickSignDisplay event={event} playerMap={props.playerMap} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('ir_transaction', event => <IrTransactionDisplay event={event} playerMap={props.playerMap} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('mid_season_extension_event', event => <MidSeasonExtensionDisplay leagueId={event.leagueId} teamId={event.teamId} details={event.details} playerMap={props.playerMap} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('practice_squad_activation_event', event => <PracticeSquadActivationDisplay leagueId={event.leagueId} teamId={event.teamId} playerMap={props.playerMap} teamMap={props.teamMap} playerId={event.playerId} amount={event.amount} />)
  
  const eventDateTime = new Date(props.event.eventDateTime);
  const linkCreator = EVENT_TYPE_LINK_CREATOR_MAP.get(props.event.type);
  const link = linkCreator ? linkCreator(props.event) : null;
  return <TableRow>
    <StyledTableCell>{eventDateTime.toLocaleString()}</StyledTableCell>
    <StyledTableCell>{link ? <Button onClick={() => navigate(link)}>{getEventTypeDisplay(props.event.type)}</Button> : getEventTypeDisplay(props.event.type)}</StyledTableCell>
    <StyledTableCell>{eventTypeDisplayMap.get(props.event.type)(props.event)}</StyledTableCell>
  </TableRow>;
}

export default LeagueEventDisplay;