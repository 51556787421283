/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TradeAcceptancePayload } from '../model';
// @ts-ignore
import { TradeProposal } from '../model';
/**
 * TradeApi - axios parameter creator
 * @export
 */
export const TradeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accept a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {TradeAcceptancePayload} [tradeAcceptancePayload] (Optional) Player IDs to drop as part of the trade acceptance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTradeProposal: async (tradeId?: string, teamId?: string, tradeAcceptancePayload?: TradeAcceptancePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/{tradeId}/team/{teamId}/accept`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeAcceptancePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel trade proposal
         * @summary Cancel and permanently delete a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTradeProposal: async (tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/{tradeId}`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Counter Trade Proposal
         * @summary Create a new Trade Proposal draft as a counter to another trade proposal
         * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
         * @param {string} [teamId] A unique identifier for a team
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counterTradeProposal: async (tradeProposal: TradeProposal, teamId?: string, tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeProposal' is not null or undefined
            assertParamExists('counterTradeProposal', 'tradeProposal', tradeProposal)
            const localVarPath = `/trades/team/{teamId}/counter/{tradeId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Trade Proposal
         * @summary Create a new Trade Proposal draft
         * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradeProposal: async (tradeProposal: TradeProposal, teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeProposal' is not null or undefined
            assertParamExists('createTradeProposal', 'tradeProposal', tradeProposal)
            const localVarPath = `/trades/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retreive the next 10 trade history items
         * @param {string} [teamId] A unique identifier for a team
         * @param {number} [itemsLoaded] The number of items already loaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeHistoryForTeam: async (teamId?: string, itemsLoaded?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/team/{teamId}/history`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (itemsLoaded !== undefined) {
                localVarQueryParameter['itemsLoaded'] = itemsLoaded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get trade proposal
         * @summary Retreive details about a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeProposal: async (tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/{tradeId}`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Trade Proposals
         * @summary Get all pending trade proposals for a team
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeProposalsForTeam: async (teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reject a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTradeProposal: async (tradeId?: string, teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/{tradeId}/team/{teamId}/reject`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit a trade proposal
         * @param {string} body The expiration date of the trade proposal.
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTradeProposal: async (body: string, tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('submitTradeProposal', 'body', body)
            const localVarPath = `/trades/{tradeId}/submit`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Undo the acceptance of a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoAcceptance: async (tradeId?: string, teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/{tradeId}/team/{teamId}/undoAcceptance`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update trade proposal
         * @summary Update the terms of an existing trade proposal
         * @param {TradeProposal} tradeProposal The new terms of the &#x60;trade_proposal&#x60;.
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTradeProposal: async (tradeProposal: TradeProposal, tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeProposal' is not null or undefined
            assertParamExists('updateTradeProposal', 'tradeProposal', tradeProposal)
            const localVarPath = `/trades/{tradeId}`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeProposal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Withdraw a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawTradeProposal: async (tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades/{tradeId}/withdraw`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradeApi - functional programming interface
 * @export
 */
export const TradeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TradeApiAxiosParamCreator(configuration)
    return {
        /**
         * Accept a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {TradeAcceptancePayload} [tradeAcceptancePayload] (Optional) Player IDs to drop as part of the trade acceptance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptTradeProposal(tradeId?: string, teamId?: string, tradeAcceptancePayload?: TradeAcceptancePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTradeProposal(tradeId, teamId, tradeAcceptancePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancel trade proposal
         * @summary Cancel and permanently delete a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTradeProposal(tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTradeProposal(tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Counter Trade Proposal
         * @summary Create a new Trade Proposal draft as a counter to another trade proposal
         * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
         * @param {string} [teamId] A unique identifier for a team
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async counterTradeProposal(tradeProposal: TradeProposal, teamId?: string, tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.counterTradeProposal(tradeProposal, teamId, tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Trade Proposal
         * @summary Create a new Trade Proposal draft
         * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTradeProposal(tradeProposal: TradeProposal, teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTradeProposal(tradeProposal, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retreive the next 10 trade history items
         * @param {string} [teamId] A unique identifier for a team
         * @param {number} [itemsLoaded] The number of items already loaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeHistoryForTeam(teamId?: string, itemsLoaded?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeProposal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeHistoryForTeam(teamId, itemsLoaded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get trade proposal
         * @summary Retreive details about a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeProposal(tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeProposal(tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Trade Proposals
         * @summary Get all pending trade proposals for a team
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeProposalsForTeam(teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeProposal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeProposalsForTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reject a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectTradeProposal(tradeId?: string, teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectTradeProposal(tradeId, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit a trade proposal
         * @param {string} body The expiration date of the trade proposal.
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTradeProposal(body: string, tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitTradeProposal(body, tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Undo the acceptance of a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoAcceptance(tradeId?: string, teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undoAcceptance(tradeId, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update trade proposal
         * @summary Update the terms of an existing trade proposal
         * @param {TradeProposal} tradeProposal The new terms of the &#x60;trade_proposal&#x60;.
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTradeProposal(tradeProposal: TradeProposal, tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTradeProposal(tradeProposal, tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Withdraw a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawTradeProposal(tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawTradeProposal(tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TradeApi - factory interface
 * @export
 */
export const TradeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TradeApiFp(configuration)
    return {
        /**
         * Accept a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {TradeAcceptancePayload} [tradeAcceptancePayload] (Optional) Player IDs to drop as part of the trade acceptance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTradeProposal(tradeId?: string, teamId?: string, tradeAcceptancePayload?: TradeAcceptancePayload, options?: any): AxiosPromise<void> {
            return localVarFp.acceptTradeProposal(tradeId, teamId, tradeAcceptancePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel trade proposal
         * @summary Cancel and permanently delete a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTradeProposal(tradeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelTradeProposal(tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Counter Trade Proposal
         * @summary Create a new Trade Proposal draft as a counter to another trade proposal
         * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
         * @param {string} [teamId] A unique identifier for a team
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counterTradeProposal(tradeProposal: TradeProposal, teamId?: string, tradeId?: string, options?: any): AxiosPromise<TradeProposal> {
            return localVarFp.counterTradeProposal(tradeProposal, teamId, tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Trade Proposal
         * @summary Create a new Trade Proposal draft
         * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradeProposal(tradeProposal: TradeProposal, teamId?: string, options?: any): AxiosPromise<TradeProposal> {
            return localVarFp.createTradeProposal(tradeProposal, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retreive the next 10 trade history items
         * @param {string} [teamId] A unique identifier for a team
         * @param {number} [itemsLoaded] The number of items already loaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeHistoryForTeam(teamId?: string, itemsLoaded?: number, options?: any): AxiosPromise<Array<TradeProposal>> {
            return localVarFp.getTradeHistoryForTeam(teamId, itemsLoaded, options).then((request) => request(axios, basePath));
        },
        /**
         * Get trade proposal
         * @summary Retreive details about a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeProposal(tradeId?: string, options?: any): AxiosPromise<TradeProposal> {
            return localVarFp.getTradeProposal(tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Trade Proposals
         * @summary Get all pending trade proposals for a team
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeProposalsForTeam(teamId?: string, options?: any): AxiosPromise<Array<TradeProposal>> {
            return localVarFp.getTradeProposalsForTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Reject a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTradeProposal(tradeId?: string, teamId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.rejectTradeProposal(tradeId, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit a trade proposal
         * @param {string} body The expiration date of the trade proposal.
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTradeProposal(body: string, tradeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.submitTradeProposal(body, tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Undo the acceptance of a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoAcceptance(tradeId?: string, teamId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.undoAcceptance(tradeId, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update trade proposal
         * @summary Update the terms of an existing trade proposal
         * @param {TradeProposal} tradeProposal The new terms of the &#x60;trade_proposal&#x60;.
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTradeProposal(tradeProposal: TradeProposal, tradeId?: string, options?: any): AxiosPromise<TradeProposal> {
            return localVarFp.updateTradeProposal(tradeProposal, tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Withdraw a trade proposal
         * @param {string} [tradeId] A unique identifier for a trade proposal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawTradeProposal(tradeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.withdrawTradeProposal(tradeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradeApi - object-oriented interface
 * @export
 * @class TradeApi
 * @extends {BaseAPI}
 */
export class TradeApi extends BaseAPI {
    /**
     * Accept a trade proposal
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {string} [teamId] A unique identifier for a team
     * @param {TradeAcceptancePayload} [tradeAcceptancePayload] (Optional) Player IDs to drop as part of the trade acceptance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public acceptTradeProposal(tradeId?: string, teamId?: string, tradeAcceptancePayload?: TradeAcceptancePayload, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).acceptTradeProposal(tradeId, teamId, tradeAcceptancePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel trade proposal
     * @summary Cancel and permanently delete a trade proposal
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public cancelTradeProposal(tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).cancelTradeProposal(tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Counter Trade Proposal
     * @summary Create a new Trade Proposal draft as a counter to another trade proposal
     * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
     * @param {string} [teamId] A unique identifier for a team
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public counterTradeProposal(tradeProposal: TradeProposal, teamId?: string, tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).counterTradeProposal(tradeProposal, teamId, tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Trade Proposal
     * @summary Create a new Trade Proposal draft
     * @param {TradeProposal} tradeProposal A new &#x60;trade_proposal&#x60; to be created.
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public createTradeProposal(tradeProposal: TradeProposal, teamId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).createTradeProposal(tradeProposal, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retreive the next 10 trade history items
     * @param {string} [teamId] A unique identifier for a team
     * @param {number} [itemsLoaded] The number of items already loaded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public getTradeHistoryForTeam(teamId?: string, itemsLoaded?: number, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).getTradeHistoryForTeam(teamId, itemsLoaded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get trade proposal
     * @summary Retreive details about a trade proposal
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public getTradeProposal(tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).getTradeProposal(tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Trade Proposals
     * @summary Get all pending trade proposals for a team
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public getTradeProposalsForTeam(teamId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).getTradeProposalsForTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reject a trade proposal
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public rejectTradeProposal(tradeId?: string, teamId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).rejectTradeProposal(tradeId, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit a trade proposal
     * @param {string} body The expiration date of the trade proposal.
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public submitTradeProposal(body: string, tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).submitTradeProposal(body, tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Undo the acceptance of a trade proposal
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public undoAcceptance(tradeId?: string, teamId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).undoAcceptance(tradeId, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update trade proposal
     * @summary Update the terms of an existing trade proposal
     * @param {TradeProposal} tradeProposal The new terms of the &#x60;trade_proposal&#x60;.
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public updateTradeProposal(tradeProposal: TradeProposal, tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).updateTradeProposal(tradeProposal, tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Withdraw a trade proposal
     * @param {string} [tradeId] A unique identifier for a trade proposal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public withdrawTradeProposal(tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).withdrawTradeProposal(tradeId, options).then((request) => request(this.axios, this.basePath));
    }
}
