import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks'
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League, Team, PlayerPoachInfo } from '../../../sdk/model';
import {Box, Typography} from '@mui/material';
import PlayerCardLink from '../../cards/playerCardLink';
import { toSalary } from '../../util/SalaryDisplay';
import MaterialReactTable from 'material-react-table';
import ActivatePlayerButton from '../team/ActivatePlayerButton';

function TeamPlayerPoachView() {
  const {leagueId, teamId} = useParams();
  const [playerSelection, setPlayerSelection] = React.useState({});
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const globalConfig: GlobalConfig = useAppSelector((state) => state.globalConfigDataLoad)
  
  const playerPoachInfo : Array<PlayerPoachInfo> | undefined = useAppSelector((state) => state.playerPoachDataLoad).get(teamId!);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: true, loadRosterFor: [], loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  function getPoachDate(dateStr) {
    if (dateStr) {
      return new Date(dateStr);
    }
    const waiverClearDate = new Date(globalConfig.nextWeekBeginDate!);
    waiverClearDate.setDate(waiverClearDate.getDate() + 1);
    return waiverClearDate;
  }
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));                                           
  const myTeam = teamMap.get(teamId);
  const teamPlayerMap = new Map(myTeam?.practiceSquad?.filter(player => player).map(player => [player.playerId, player]));
  const columns = React.useMemo(() => 
    [
      {
        accessorKey: 'deadline',
        id: 'deadline',
        header: 'Poach Date',
        minSize: 15,
        size: 15,
        Cell: ({renderedCellValue}) => {
          const date = getPoachDate(renderedCellValue);
          return date.toLocaleDateString(undefined, { weekday: 'long' }) + " at " + date.toLocaleTimeString()
        }
      },
      {
        accessorKey: 'player.abbr_name',
        id: 'player',
        header: 'Player',
        minSize: 15,
        size: 15,
        Cell: ({ row }) => <PlayerCardLink player={row.original.player} leagueId={leagueId!} />
      },
      {
        accessorKey: 'topOffer',
        id: 'topOffer',
        header: 'Top Offer',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue }) => toSalary(renderedCellValue)
      }
    ], []
  );
  const data = playerPoachInfo ? playerPoachInfo.map(playerPoach => {
    return {...playerPoach, player: playerMap.get(playerPoach.playerId)}
  })! : [];
  const selectedPlayerInfo = Object.keys(playerSelection).length > 0 ?
    teamPlayerMap.get(Object.keys(playerSelection)[0]) : undefined;
  return <Box>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Poach Claims
    </Typography>
    {Object.keys(playerSelection).length > 0 && 
      <ActivatePlayerButton leagueId={leagueId!} player={playerMap.get(selectedPlayerInfo?.playerId)!} contract={selectedPlayerInfo?.contract!} currentSeasonYear={league?.currentSeasonYear!} team={myTeam} />
    }
    <MaterialReactTable columns={columns} data={data} enableRowSelection={true} enableMultiRowSelection={false} 
      getRowId={(row) => row.player?.id!} onRowSelectionChange={setPlayerSelection} state={{ rowSelection : playerSelection }}></MaterialReactTable>
  </Box>
}

export default TeamPlayerPoachView;