/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Roster Position (Order is important, it is the order shown on the frontend)
 * @export
 * @enum {string}
 */

export const RosterPositionEnum = {
    Qb: 'QB',
    Wr: 'WR',
    Rb: 'RB',
    Te: 'TE',
    Flex: 'FLEX',
    Sflex: 'SFLEX',
    Dflex: 'DFLEX',
    Def: 'DEF',
    Dl: 'DL',
    Lb: 'LB',
    Db: 'DB',
    P: 'P',
    K: 'K',
    Bn: 'BN',
    Ir: 'IR',
    Ps: 'PS'
} as const;

export type RosterPositionEnum = typeof RosterPositionEnum[keyof typeof RosterPositionEnum];



