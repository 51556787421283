import React from 'react';
import {League} from '../../sdk/model';
import {useLoadActionConfigs} from '../../app/dataLoaderHooks';

export function useValidateAction(action: string, league: League | undefined) {
  const [loadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  
  const actionConfigs = useLoadActionConfigs({setLoadComplete, setIsException});
  
  if (!loadComplete || isException || !league) {
    return false;
  }
  
  return actionConfigs[action]!.allowedPhases!.includes(league.phase!) &&
    (action !== 'trade' || !league.currentSeason?.tradeDeadlineWeek || 
      league.currentSeason.tradeDeadlineWeek >= league.currentSeason.currentWeek!);
}
