import * as React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League, Team, TradeProposalPlayer, Player, TeamPlayerInfo, TradeProposalPick, DraftPick, TradeProposalPlayerActionEnum, RosterPositionEnum } from '../../../sdk/model';
import {Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableBody} from '@mui/material';
import { toSalary, getNewSalary, getSalaryForSeason, getMinimumCostForFreeAgents } from '../../util/SalaryDisplay';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { displayDraftPick } from '../../util/DraftPickDisplay';
import { getRosterSize } from '../../util/TeamUtils';
import { MINIMUM_ONE_YEAR_SALARY } from '../../util/ContractUtils';

interface tradeDetailsProps {
  leagueId: string,
  teams: Array<Team>,
  picks: Array<TradeProposalPick>,
  currentSeason: number | undefined,
  players: Array<TradeProposalPlayer>,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team | undefined>,
  teamPlayerMap: Map<string | undefined, TeamPlayerInfo | undefined>,
  pickMap: Map<string | undefined, DraftPick | undefined>,
  salaryCap: number,
  maxRosterSize: number
}

const SEASONS_TO_DISPLAY = 5;

const NON_ACQUIRE_ACTIONS = new Set(['MOVE_TO_IR', 'RETURN_FROM_IR', 'ACTIVATE']);

function dropToSalary(teamPlayer: TeamPlayerInfo, currentSeason: number) {
  return [...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
    const contractSeason = teamPlayer?.contract?.seasons?.find(season => season.season_year===currentSeason + index)!;
    if (!contractSeason) {
      return 0;
    }
    if (index === 0) {
      return contractSeason.weeks!.reduce((partialSum, week) => partialSum + week.fullSalary! - Math.max(week.guaranteedSalary!, (week.payments ? week.payments.filter(payment => payment.completed || !payment.teamId).reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price!, 0) : 0)), 0);
    }
    if (index === 1) {
      const committedSalary = contractSeason.weeks!.flatMap(week => week.payments).filter(payment => !payment!.completed).reduce((partialSum, payment) => partialSum + payment?.price!, 0);
      return committedSalary - [...Array(SEASONS_TO_DISPLAY).keys()].filter(acceleratedIndex => acceleratedIndex !== 0).reduce((accelerationSum, acceleratedIndex) => {
        const contractSeason = teamPlayer?.contract?.seasons?.find(season => season.season_year===currentSeason + acceleratedIndex);
        return accelerationSum + (contractSeason ? contractSeason.weeks!.reduce((partialSum, week) => partialSum + week.guaranteedSalary! - week.payments!.filter(payment => payment.completed).reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price!, 0), 0) : 0);
      }, 0);
    }
    return 0;
  })
}

function irSalary(teamPlayer: TeamPlayerInfo, currentSeason: number) {
  return [...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
    const contractSeason = teamPlayer?.contract?.seasons?.find(season => season.season_year===currentSeason + index)!;
    if (!contractSeason) {
      return 0;
    }
    if (index === 0) {
      return contractSeason.weeks!.reduce((partialSum, week) => partialSum + week.fullSalary! - (week.payments ? week.payments.reduce((partialPaymentSum, payment) => partialPaymentSum + (payment.completed ? payment.price! : payment.price! / 2), 0) : 0), 0);
    }
    return contractSeason.weeks!.reduce((partialSum, week) => partialSum + week.fullSalary! - (week.payments ? week.payments.filter(payment => payment.completed).reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price!, 0) : 0), 0);
  })
}

function activateSalary(teamPlayer: TeamPlayerInfo, currentSeason: number, tradedPlayer : TradeProposalPlayer, totalWeeks : number) {
  const activationSalaryPerWeek = tradedPlayer.newBidValue! / totalWeeks;
  return [...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
    const contractSeason = teamPlayer?.contract?.seasons?.find(season => season.season_year===currentSeason + index)!;
    if (!contractSeason) {
      return 0;
    }
    return contractSeason.weeks!.reduce((partialSum, week) => partialSum + activationSalaryPerWeek - (week.payments ? week.payments.reduce((partialPaymentSum, payment) => partialPaymentSum + (payment.completed ? activationSalaryPerWeek : payment.price!), 0) : 0), 0);
  })
}

function receivePlayerSalary(teamPlayer: TeamPlayerInfo, currentSeason: number, tradedPlayer : TradeProposalPlayer) {
  return [...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
    const contractSeason = teamPlayer?.contract?.seasons?.find(season => season.season_year===currentSeason + index);
    return contractSeason ? contractSeason.weeks!.flatMap(week => week.payments).filter(payment => payment && !payment.completed && (tradedPlayer.removeFromIr || payment.teamId)).reduce((partialSum, payment) => partialSum + payment?.price!, 0) : 0;
  });
}

function defaultToSalary(teamPlayer: TeamPlayerInfo, currentSeason: number, tradedPlayer : TradeProposalPlayer) {
  return [...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
    const contractSeason = teamPlayer?.contract?.seasons?.find(season => season.season_year===currentSeason + index);
    return contractSeason ? contractSeason.weeks!.flatMap(week => week.payments).filter(payment => payment && !payment.completed && payment.teamId).reduce((partialSum, payment) => partialSum + payment?.price!, 0) : 0;
  });
}

const ACTION_TO_SALARY_FUNCTION = new Map([
  [TradeProposalPlayerActionEnum.Drop.valueOf(), dropToSalary],
  [TradeProposalPlayerActionEnum.MoveToIr.valueOf(), irSalary],
  [TradeProposalPlayerActionEnum.ReturnFromIr.valueOf(), irSalary],
  [TradeProposalPlayerActionEnum.Activate.valueOf(), activateSalary],
  ["RECEIVE", receivePlayerSalary]
]);

function TradeDetails(props: tradeDetailsProps) {
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
   
  const currentSeason = props.currentSeason ? props.currentSeason : 1;
  function getPlayerDetails(tradedPlayer : TradeProposalPlayer, action : string, factor : number) {
    const player = props.playerMap.get(tradedPlayer.playerId);
    const teamPlayer = props.teamPlayerMap.get(tradedPlayer.playerId);
    const salaryFunction = ACTION_TO_SALARY_FUNCTION.get(action) || defaultToSalary;
    const salaryChanges = salaryFunction.call(this, teamPlayer, currentSeason, tradedPlayer, league?.currentSeason?.finalWeek!);
    return <TableRow key={tradedPlayer.playerId}>
      <StyledTableCell>{player?.abbr_name}</StyledTableCell>
      <StyledTableCell>{action}</StyledTableCell>
      {salaryChanges.map((salary, salaryIndex) => {
        const salaryImpact = salary * factor;
        const color = salaryImpact > 0 ? '#FF0000' : (salaryImpact < 0 ? '#00FF00' : '#FFFFFF')
        return <StyledTableCell key={salaryIndex} sx={{color: color}}>{salaryImpact ? toSalary(Math.abs(salaryImpact)) : '-'}</StyledTableCell>
      })}
    </TableRow>
  }
  function getPickDetails(tradedPick : TradeProposalPick, action : string, factor : number) {
    const pick = props.pickMap.get(tradedPick?.draftPickId);
    const annualSalaries = [...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
      const contractSeason = pick?.contract?.seasons?.find(season => season.season_year===currentSeason + index);
      return contractSeason ? contractSeason.annual_salary : 0;
    });
    const originalTeam = props.teamMap.get(pick?.originalTeamId);
    return <TableRow key={tradedPick.draftPickId}>
      <StyledTableCell>{displayDraftPick(pick, originalTeam)}</StyledTableCell>
      <StyledTableCell>{action}</StyledTableCell>
      {annualSalaries.map((salary, salaryIndex) => {
        const salaryImpact = salary * factor;
        const color = salaryImpact > 0 ? '#FF0000' : (salaryImpact < 0 ? '#00FF00' : '#FFFFFF')
        return <StyledTableCell key={salaryIndex} sx={{color: color}}>{salaryImpact ? toSalary(Math.abs(salaryImpact)) : "-"}</StyledTableCell>
      })}
    </TableRow>
  }
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return <Box>
    <Typography variant="span" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      (Salary Cap: {toSalary(props.salaryCap)})
    </Typography>
    {props.teams.filter(team => team).map(team => {
      const tradedPlayers = props.players.filter(player => player.fromTeamId === team.id && player.action === 'TRADE');
      const tradedPicks = props.picks.filter(pick => pick.fromTeamId === team.id);
      const receivedPlayers = props.players.filter(player => player.toTeamId === team.id && !NON_ACQUIRE_ACTIONS.has(player.action!));
      const irPlayers = props.players.filter(player => player.toTeamId === team.id && player.action === 'MOVE_TO_IR');
      const returnFromIrPlayers = props.players.filter(player => player.toTeamId === team.id && player.action === 'RETURN_FROM_IR');
      const activatedPlayers = props.players.filter(player => player.action === 'ACTIVATE');
      const receivedPicks = props.picks.filter(pick => pick.toTeamId === team.id);
      const droppedPlayers = props.players.filter(player => player.fromTeamId === team.id && player.action === 'DROP')
      const salariesBySeason = [...Array(SEASONS_TO_DISPLAY).keys()].map(index => getSalaryForSeason(team.paymentsBySeason![currentSeason + index]));
      const rosterSize = getRosterSize(team);
      const emptyRosterSpots = props.maxRosterSize - rosterSize;
      const newRosterSize = props.players.reduce((partialSum, tradePlayer) => {
        const owningTeam = props.teamMap.get(tradePlayer.fromTeamId);
        const isIr = owningTeam?.playerRoster![RosterPositionEnum.Ir].some(player => player && player.playerId === tradePlayer.playerId);
        return partialSum + (tradePlayer.toTeamId === team?.id && tradePlayer.action !== 'MOVE_TO_IR' && (!isIr || tradePlayer.removeFromIr) ? 1 : (tradePlayer.fromTeamId === team?.id && !isIr ? -1 : 0));
      }, rosterSize);
      const newEmptyRosterSpots = props.maxRosterSize - newRosterSize;
      const changeInEmptyRosterSpots = newEmptyRosterSpots > 0 ? Math.max(newEmptyRosterSpots - emptyRosterSpots, 0) : 0;
      return <Box key={team.id}>
        <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          {team.name}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{fontSize : 20}}>
              <TableRow>
                <StyledTableCell colSpan={2}>Description</StyledTableCell>
                <StyledTableCell colSpan={SEASONS_TO_DISPLAY}>Salary by Season</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Asset</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                {[...Array(SEASONS_TO_DISPLAY).keys()].map(index => <StyledTableCell key={index}>{currentSeason + index}</StyledTableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell colSpan={2}><b>Current Salary</b></StyledTableCell>
                {[...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
                  let capSpace: number | undefined;
                  const oldSalary = salariesBySeason[index]
                  if (index === 0) {
                    capSpace = props.salaryCap - oldSalary;
                  }
                  return <StyledTableCell key={index}><b>{toSalary(oldSalary)}{capSpace ? " (" + toSalary(capSpace) + " space)" : ""}</b></StyledTableCell>
                })}
              </TableRow>
              {tradedPlayers.map(tradedPlayer => getPlayerDetails(tradedPlayer, "TRADE", -1))}
              {tradedPicks.map(tradedPick => getPickDetails(tradedPick, "TRADE", -1))}
              {receivedPlayers.map(tradedPlayer => getPlayerDetails(tradedPlayer, "RECEIVE", 1))}
              {irPlayers.map(tradedPlayer => getPlayerDetails(tradedPlayer, "MOVE_TO_IR", -1))}
              {returnFromIrPlayers.map(tradedPlayer => getPlayerDetails(tradedPlayer, "RETURN_FROM_IR", 1))}
              {activatedPlayers.map(tradedPlayer => getPlayerDetails(tradedPlayer, "ACTIVATE", 1))}
              {receivedPicks.map(tradedPick => getPickDetails(tradedPick, "RECEIVE", 1))}
              {droppedPlayers.map(tradedPlayer => getPlayerDetails(tradedPlayer, "DROP", -1))}
              {changeInEmptyRosterSpots > 0 && <TableRow>
                <StyledTableCell colSpan={2}>Minimum Cost for empty roster spots ({changeInEmptyRosterSpots})</StyledTableCell>
                <StyledTableCell sx={{color: '#FF0000'}}>{toSalary(getMinimumCostForFreeAgents(changeInEmptyRosterSpots, league?.currentSeason!))}</StyledTableCell>
                {[...Array(SEASONS_TO_DISPLAY - 1).keys()].map(index => 
                  <StyledTableCell key={index}>-</StyledTableCell>
                )}
              </TableRow>}
              <TableRow>
                <StyledTableCell colSpan={2}><b>Salary After Transaction</b></StyledTableCell>
                {[...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
                  const newSalary = getNewSalary(team, props.teamPlayerMap, props.pickMap, salariesBySeason[index], props.players, props.picks, currentSeason + index, index, index === 0 ? changeInEmptyRosterSpots : undefined, league?.currentSeason)
                  let capSpace: number | undefined;
                  let color = '#FFFFFF';
                  if (index === 0) {
                    capSpace = props.salaryCap - newSalary;
                    if (capSpace < 0) {
                      color = '#FF0000';
                    }
                  }
                  
                  return <StyledTableCell key={index} sx={{color: color}}><b>{toSalary(newSalary)}{capSpace ? " (" + toSalary(capSpace) + " space)" : ""}</b></StyledTableCell>
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    })}
  </Box>
}

export default TradeDetails;
