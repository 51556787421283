import * as React from 'react'
import { Box, Typography } from '@mui/material';
import { League, Team, Player } from '../../../sdk/model';
import RosterContractDisplay from './RosterContractDisplay';
import TeamDraftPickDisplay from './TeamDraftPickDisplay';
import DeadCapReport from './DeadCapReport';
import {getRemainingRosterSpots} from '../../util/TeamUtils';
import { MINIMUM_ONE_YEAR_SALARY } from '../../util/ContractUtils';
import { toSalary } from '../../util/SalaryDisplay';

interface teamAssetsProps {
  league: League,
  team: Team,
  myTeam: boolean,
  currentSeason: number,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team | undefined>
}

function TeamAssets(props: teamAssetsProps) {
  const emptyRosterSpots = getRemainingRosterSpots(props.team, props.league);
  const emptyRosterSpotMinCost = emptyRosterSpots * MINIMUM_ONE_YEAR_SALARY;
  const activeContracts = new Set(Object.values(props.team.playerRoster!).flat().filter(player => player).map(player => player.contract?.id));
  const contractsWithPayments = new Set(Object.values(props.team.paymentsBySeason!).flat().map(payment => payment.contractId!));
  const deadCapContractIds = [...contractsWithPayments].filter(contractId => !activeContracts.has(contractId));
  return <Box>
    <RosterContractDisplay team={props.team!} myTeam={props.myTeam} currentSeason={props.currentSeason} otherTeamIds={[]}
        teamMap={undefined} proposalPlayerMap={undefined} playerMap={props.playerMap}
        handleTradeProposalChange={undefined} earliestLockDate={null} />
    <TeamDraftPickDisplay team={props.team} teamMap={props.teamMap} myTeam={props.myTeam} currentSeason={props.currentSeason} />
    {deadCapContractIds.length > 0 &&
      <DeadCapReport deadCapContractIds={deadCapContractIds} teamId={props.team.id} currentSeason={props.currentSeason} playerMap={props.playerMap} teamMap={props.teamMap} />
    }
    {emptyRosterSpots > 0 &&
      <Typography>Empty Roster Spots: {emptyRosterSpots} X (minimum) {toSalary(MINIMUM_ONE_YEAR_SALARY)} = {toSalary(emptyRosterSpotMinCost)}</Typography>
    }
  </Box>
}

export default TeamAssets;
