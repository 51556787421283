import * as React from 'react';
import { Player } from '../../sdk/model';
import { Modal, Button, Avatar, Box } from '@mui/material';
import PlayerInfoCard from './playerCard';

interface playerCardLinkProps {
  player: Player | undefined,
  leagueId: string
}

// TODO: Deal with an undefined player
function PlayerCardLink(props: playerCardLinkProps) {
  const [viewingPlayer, setViewingPlayer] = React.useState<Player | undefined>(undefined);
  return <React.Fragment>
    <Modal open={viewingPlayer !== undefined} onClose={() => setViewingPlayer(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <PlayerInfoCard player={viewingPlayer} leagueId={props.leagueId}/>
    </Modal>
    <Button onClick={() => setViewingPlayer(props.player)}>
      <Avatar sx={{display: 'inline-block'}} alt={props.player?.abbr_name} src={props.player?.picture}></Avatar>
      <Box>{props.player?.abbr_name}<br/>({props.player?.position} - {props.player?.nfl_team?.alias})</Box><Box sx={{color: 'red', marginLeft: 1}}>{props.player?.injury?.designation}</Box>
    </Button>
  </React.Fragment>
}

export default PlayerCardLink;

