import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { GameLog, NflPlayerGameStatistics, Player, PlayerGameScore, ScoringSettings } from '../../../sdk/model';
import MaterialReactTable from 'material-react-table';
import { nflGameApi } from '../../../adapters/APIExporter';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, ThemeProvider, Tooltip, Typography } from '@mui/material';
import { defaultTableTheme } from '../theme/tableTheme';
import { Sorting } from '@tanstack/react-table';
import { getPlayerScoreFromStats } from '../../util/ScoreService';
import { ScoreToolTip } from '../../util/GameUtil';

interface gameLogProps {
  gameLog: NflPlayerGameStatistics[],
  season: number,
  scoringSettings: ScoringSettings,
  player: Player
}

export default function GameLogComponent(props: gameLogProps) {
  calculateScore(props.gameLog, props.scoringSettings ,props.player);
  let columns;
  switch(props.player.position) {
    case "WR":
      columns = wrColumns;
      break;
    case "RB":
      columns = rbColumns;
      break;
    case "QB":  
      columns = qbColumns;
      break;
    default:
      columns = wrColumns;
  }
  return (
    <><Typography variant="h6" sx={{ textAlign: 'center', marginBottom: '10px' }}>
      {props.player.first_name} {props.player.last_name}'s {props.season} Season
    </Typography><MaterialReactTable
        columns={columns}
        data={sortGameLogs(props.gameLog)}
        enableRowOrdering={false} enableSorting={false} enableFilters={false} enablePagination={false}
        muiTableBodyCellProps={{
          sx: {
            borderRight: '2px solid #e0e0e0', //add a border between columns
          },
        }} /></>
  );

}

// Common Columns (these are ones shared for all positions)
function createCommonColumns(theme) {
  return [
    opponentColumn(theme.palette.primary.light),
    column('week', 'Week', 'week', theme.palette.primary.dark),
    scoreColumn(theme.palette.secondary.main),
  ];
}

// WR Columns
const wrColumns = 
  [
    ...createCommonColumns(defaultTableTheme),

    columnHeader('receiving','Receiving', defaultTableTheme.palette.primary.main,
      [
        column('receiving_yards', 'Yards', 'statistics.receiving.yards', defaultTableTheme.palette.primary.light),
        column('receptions', 'Receptions', 'statistics.receiving.receptions', defaultTableTheme.palette.primary.dark),
        column('receiving_tds', 'TDs', 'statistics.receiving.touchdowns', defaultTableTheme.palette.primary.light),
      ]
    ),

    columnHeader('rushing','Rushing', defaultTableTheme.palette.secondary.main,
      [
        column('rushing_yards', 'Yards', 'statistics.rushing.yards', defaultTableTheme.palette.secondary.light),
        column('rushing_tds', 'TDs', 'statistics.rushing.touchdowns', defaultTableTheme.palette.secondary.dark),
      ]
    ),
  ];

  // RB Columns
  const rbColumns = 
    [
      ...createCommonColumns(defaultTableTheme),

      columnHeader('rushing','Rushing', defaultTableTheme.palette.primary.main,
        [
          column('rushing_yards', 'Yards', 'statistics.rushing.yards', defaultTableTheme.palette.primary.light),
          column('rushing_tds', 'TDs', 'statistics.rushing.touchdowns', defaultTableTheme.palette.primary.dark),
        ]
      ),
  
      columnHeader('receiving','Receiving', defaultTableTheme.palette.secondary.main,
        [
          column('receiving_yards', 'Yards', 'statistics.receiving.yards', defaultTableTheme.palette.secondary.light),
          column('receptions', 'Receptions', 'statistics.receiving.receptions', defaultTableTheme.palette.secondary.dark),
          column('receiving_tds', 'TDs', 'statistics.receiving.touchdowns', defaultTableTheme.palette.secondary.light),
        ]
      ),
    ];


  // QB Columns
  const qbColumns = 
  [
    ...createCommonColumns(defaultTableTheme),

    columnHeader('passing','Passing', defaultTableTheme.palette.primary.main,
      [
        column('passing_yards', 'Yards', 'statistics.passing.yards', defaultTableTheme.palette.primary.light),
        column('passing_tds', 'TDs', 'statistics.passing.touchdowns', defaultTableTheme.palette.primary.dark),
        column('passing_ints', 'INTs', 'statistics.passing.interceptions', defaultTableTheme.palette.primary.light),
      ]
    ),
    columnHeader('rushing','Rushing', defaultTableTheme.palette.secondary.main,
      [
        column('rushing_yards', 'Yards', 'statistics.rushing.yards', defaultTableTheme.palette.secondary.light),
        column('rushing_tds', 'TDs', 'statistics.rushing.touchdowns', defaultTableTheme.palette.secondary.dark),
      ]
    ),
  ];






  // Helper Functions

  function columnHeader(id, header, color, columns) {
    return {
      id: id,
      header: header,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: color,
        },
      },
      columns
    };
  }

  function column(id: string, header: string, key: string, color: string) {
    return {
      accessorFn: (log) => getKey(log, key),
      id: id,
      header: header,
      minSize: 15,
      size: 15,
      muiTableBodyCellProps: {
        sx: {
          backgroundColor: color,
        },
      },  
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: color,
        },
      },  
    };
  }

  function opponentColumn(color: string) {
    return {
      accessorFn: (log : NflPlayerGameStatistics) => getOpponent(log),
      id: "opponent",
      header: "Opponent",
      minSize: 5,
      size: 10,
      muiTableBodyCellProps: {
        sx: {
          backgroundColor: color,
        },
      },  
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: color,
        },
      },  
    };
  }


  function scoreColumn(color: string) {
    return {
      accessorKey: "score",
      id: "score",
      header: "Score",
      minSize: 5,
      size: 10,
      muiTableBodyCellProps: {
        sx: {
          backgroundColor: color,
        },
      },  
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: color,
        },
      }, 
      Cell: ({ cell }) => (
        <ScoreToolTip playerGameScore={cell?.getValue<PlayerGameScore>()} >
          <span>{cell?.getValue<PlayerGameScore>().totalScore}</span>
        </ScoreToolTip>
      ),
    };
  }



  // Utility Function that gets the key from an object or returns 0
  function getKey(obj, key: string) {
    const keys = key.split('.');
    return keys.reduce((o, k) => (o || {})[k], obj) ?? 0;
  }
  
  function getOpponent(obj : NflPlayerGameStatistics) {
    if(obj.isBye) 
      return "BYE";
    const opponent = obj.opponent;
    return obj.isHome ? `${opponent}` : `@${opponent}`;
  }

  function sortGameLogs(gameLog: NflPlayerGameStatistics[]) {
    if (!gameLog) {
      console.debug("No game log found")
      return [];
    }
    return gameLog.sort((a, b) => {
      // Convert the week values to numbers before comparing
      const weekA = Number(a.week);
      const weekB = Number(b.week);
  
      return weekA - weekB;
    });

  }

function calculateScore(gameLog: NflPlayerGameStatistics[], scoringSettings: ScoringSettings , player: Player) {
  gameLog.forEach((game) => {
    game['score'] = getPlayerScoreFromStats(player, scoringSettings, game, undefined) ?? "0";
  });
}
