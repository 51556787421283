import { useAppDispatch, useAppSelector } from './hooks';
import { setPlayerDataLoad } from '../slices/playerDataLoad';
import { setNflGameDataLoad } from '../slices/nflGameDataLoad';
import { setLeagueDataLoad } from '../slices/leagueDataLoad';
import { setTradeDataLoad } from '../slices/tradeDataLoad';
import { setPlayerClaimDataLoad } from '../slices/playerClaimDataLoad';
import { setPlayerPoachDataLoad } from '../slices/playerPoachDataLoad';
import { setActionConfigDataLoad } from '../slices/actionConfigDataLoad';
import { configApi, leagueApi, leagueSeasonApi, leagueEventApi, tradeApi, teamApi, contractApi, playerClaimApi } from '../adapters/APIExporter';
import merge from 'deepmerge';
import { ActionConfig, League, LeagueSeason, TradeProposal, ContractStatus, TeamPlayerClaims, NflTeam, PlayerPoachInfo } from '../sdk/model';
import React, { useState } from 'react';

interface ActionConfigDataLoad {
  setIsException: (isException: boolean) => void,
  setLoadComplete: (isLoadComplete: boolean) => void,
}

export function useLoadActionConfigs(dataLoad: ActionConfigDataLoad): Map<string, ActionConfig> {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  
  var actionConfigs = useAppSelector((state) => state.actionConfigDataLoad);
  
  if (isLoading) {
    return new Map();
  }
  if (isLoaded) {
    return actionConfigs;
  }
  
  setIsLoading(true);
  
  configApi.getActionConfigs().then((resp) => {
    dispatch(setActionConfigDataLoad(resp.data));
    setIsLoading(false);
    setIsLoaded(true);
    dataLoad.setLoadComplete(true);
  }
  ).catch((err) => {
    setIsLoading(false); 
    setIsLoaded(true);
    dataLoad.setIsException(true);
    throw err;
  });

  return actionConfigs;
}

// TODO: We may want to think about how we want to handle reloading cached data. I want to avoid adding to much complexity to the hooks so maybe just a simple hook that will delete the cached data and then reload it.
interface LeagueDataLoad {
  userId: string | undefined,
  loadRoster: boolean,
  setIsException: (isException: boolean) => void,
  setLoadComplete: (isLoadComplete: boolean) => void,
}

export function useLoadLeagueDataQuickView(dataLoad: LeagueDataLoad) {
  // Navigator/Dispatcher
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  console.trace("Starting to Load League Data Quick View (loading: " + isLoading + ")");

  // State
  var leagues = useAppSelector((state) => state.leagueDataLoad);
  
  if (!dataLoad.userId) {
	  return [];
  }

  // Coupling the loading of the league data with the loading the data itself (i.e. check to see if the league exist to decide if we should call the backend)
  // Was causing some weirdness while loading the data. So we are going to decouple the two and keep all the loading information directly in the frontend.
  // Note: I think there are libraries for doing this, but that would be a major change since we are working so closely with Openapi Generator
  if (isLoading) {
    return [];
  }
  if (isLoaded) {
    return leagues;
  }

  setIsLoading(true);
  
  var res = leagueApi.getLeagues(true, dataLoad.userId).then((resp) => {
    dispatch(setLeagueDataLoad(resp.data));
    console.trace("Load League Data Quick View complete");
    setIsLoading(false);
    setIsLoaded(true);
    dataLoad.setLoadComplete(true);
  }
  ).catch((err) => {
    console.trace("Load League Data Quick View error");
    setIsLoading(false); 
    setIsLoaded(true);
    dataLoad.setIsException(true);
    throw err;
  });

  return leagues;

}

interface LoadLeagueSeasonData {
  leagueId: string | undefined,
  userId: String | undefined,
  loadBasicDraftData: boolean,
  loadFullDraftData: boolean,
  loadBasicAuctionData: boolean,
  loadFullAuctionData: boolean,
  loadContractData: boolean,
  loadRosterData: boolean,
  loadRosterFor: string[],
  loadWaivedContracts: boolean,
  loadScheduleData: boolean,
  isException: boolean,
  setIsException: (isException: boolean) => void,
  isLoadComplete: boolean,
  setLoadComplete: (isLoadComplete: boolean) => void,
}

// TODO: If this gets called a bunch (which it probably will) it will spam the backend with requests
export function useLoadLeagueData(dataLoad: LoadLeagueSeasonData) {
  // Navigator/Dispatcher
  const dispatch = useAppDispatch();
  // Stage 1 Load League
  const [isLoadingLeague, setIsLoadingLeague] = useState(false);
  const [isLoadedLeague, setIsLoadedLeague] = useState(false);
  // Stage 2 Load Season
  const [isLoadingSeason, setIsLoadingSeason] = useState(false);
  const [isLoadedSeason, setIsLoadedSeason] = useState(false);
  // Stage 3 (Optional) Load Full Draft
  const [isLoadingFullDraft, setIsLoadingFullDraft] = useState(false);
  const [isLoadedFullDraft, setIsLoadedFullDraft] = useState(false);
  // Stage 4 (Optional) Load Waived Contracts
  const [isLoadingWaivedContracts, setIsLoadingWaivedContracts] = useState(false);
  const [isLoadedWaivedContracts, setIsLoadedWaivedContracts] = useState(false);
  // Stage 5 (Optional) Load Schedule Data
  const [isLoadingScheduleData, setIsLoadingScheduleData] = useState(false);
  const [isLoadedScheduleData, setIsLoadedScheduleData] = useState(false);
  
  const [isLoadingRoster, setIsLoadingRoster] = useState(false);
  const [newTeam, setNewTeam] = useState(null);
  const [oldLoadRosterFor, setOldLoadRosterFor] = useState([]);
  const [oldLeagueId, setOldLeagueId] = useState(dataLoad.leagueId);
  
  React.useEffect(() => {
    if ((dataLoad.loadRosterFor && (dataLoad.loadRosterFor.length !== oldLoadRosterFor.length || !oldLoadRosterFor.every((val, index) => val === dataLoad.loadRosterFor[index]))) ||
        dataLoad.leagueId !== oldLeagueId) {
      setIsLoadingLeague(false);
      setIsLoadedLeague(false);
      setIsLoadingSeason(false);
      setIsLoadedSeason(false);
      setIsLoadingFullDraft(false);
      setIsLoadedFullDraft(false);
      setIsLoadingRoster(false);
      dataLoad.setLoadComplete(false);
      setOldLoadRosterFor(dataLoad.loadRosterFor);
      setOldLeagueId(dataLoad.leagueId);
    }
  }, [dataLoad.loadRosterFor, dataLoad.leagueId]);

  // Set State
  var leagues: League[] = useAppSelector((state) => state.leagueDataLoad);
  // Find relevant league
  var league: League | undefined = findLeague(dataLoad.leagueId! , leagues);
  
  React.useEffect(() => {
    if (newTeam) {
      const newLeague = {...league};
      newLeague.teams = league!.teams.map(existingTeam => {
        if (existingTeam.id === newTeam.id) {
          newTeam.owners = existingTeam?.owners;
          return newTeam;
        }
        return existingTeam;
      });
      dispatch(setLeagueDataLoad(replaceLeague(newLeague, leagues)));
      setNewTeam(null);
    }
  }, [newTeam]);

  // If there is a data load exception return undefined
  if(dataLoad.isException) {
    return undefined;
  }

  // If the league is already loaded return it
  if(dataLoad.isLoadComplete) {
    return league;
  }

  //////////////////////////////////////
  // LOAD LEAGUE INFO
  //////////////////////////////////////
  if(!isLoadedLeague) {
    // If the league is currently loading return undefined
    if(isLoadingLeague) {
      return undefined;
    }

    if(league != undefined) {
      // If we don't care about roster data return the league
      if(!dataLoad.loadRosterData && (!dataLoad.loadRosterFor || !dataLoad.loadRosterFor.length)) {
        setIsLoadedLeague(true);
        return league;
      } else if(league.teams.every(team => team.rosterLoaded)) {
        setIsLoadedLeague(true);
        return league;
      } else if(!dataLoad.loadRosterData && league.teams.filter(team => dataLoad.loadRosterFor.includes(team.id!)).every(team => team.rosterLoaded)) {
        // Otherwise if the roster is loaded return the league
        setIsLoadedLeague(true);
        return league;
      } else if (isLoadingRoster) {
        return league;  
      } else if (dataLoad.loadRosterData) {
        teamApi.getTeams(dataLoad.leagueId!).then(response => {
          const newLeague = {...league};
          newLeague.teams = response.data.map(team => {
            const existingTeam = league!.teams.find(oldTeam => oldTeam.id === team.id);
            team.owners = existingTeam?.owners;
            return team;
          });
          dispatch(setLeagueDataLoad(replaceLeague(newLeague, leagues)));
        });
        setIsLoadingRoster(true);
        return league;
      } else {
        dataLoad.loadRosterFor.filter(teamId => teamId && !league!.teams.find(leagueTeam => leagueTeam.id === teamId)!.rosterLoaded).forEach(teamId => 
          teamApi.getTeam(teamId).then(response => {
            setNewTeam(response.data);
          })
        );
        setIsLoadingRoster(true);
        return league;
      }
    }

    // Load League Data
    loadLeagueData(dataLoad.leagueId, dataLoad.userId, leagues,
      dataLoad.loadContractData, dataLoad.loadRosterData, dispatch,
      setIsLoadingLeague, setIsLoadedLeague, dataLoad.setIsException);
    return undefined;
  }


  //////////////////////////////////////  
  // LOAD SEASON INFO
  //////////////////////////////////////
  if(!isLoadedSeason) {
    // If the season is loading, return undefined
    if(isLoadingSeason) {
      return undefined;
    } else if (league?.currentSeason) {
    // Check to see if the data is there if it is then set to loaded continue on
      setIsLoadedSeason(true);
      return league;
    } else {
      // Load Season Data
      loadSeasonData(league, leagues, dataLoad.loadBasicDraftData, dataLoad.loadBasicAuctionData, dispatch,
        setIsLoadingSeason, setIsLoadedSeason, dataLoad.setIsException);
      return undefined;
    }
  } 
  
  //////////////////////////////////////
  // LOAD FULL DRAFT INFO
  //////////////////////////////////////
  if(!isLoadedFullDraft) {
    // If the season is loading, return undefined
    if(isLoadingFullDraft) {
      return undefined;
    }
    // If we are not required to load full draft, then set isLoaded to true so we skip this step
    else if (!dataLoad.loadFullDraftData) {
      setIsLoadedFullDraft(true);
      return league;
    }
    // If there is no draft scheduled, there is no more data to be pulled
    else if (!league?.currentSeason?.draft || !league?.currentSeason?.draft?.draftOrder) {
      console.log("Draft not scheduled.... skipping...");
      setIsLoadedFullDraft(true);
    } else {
      // Load Season Data
      loadFullDraftData(league, leagues, dispatch,
        setIsLoadingFullDraft, setIsLoadedFullDraft, dataLoad.setIsException);
      return undefined;
    }
  }
  
  //////////////////////////////////////
  // LOAD WAIVED CONTRACT INFO
  //////////////////////////////////////
  if(!isLoadedWaivedContracts) {
    if (isLoadingWaivedContracts) {
      return undefined;
    } else if (!dataLoad.loadWaivedContracts || league?.waivedContractsLoaded) {
      setIsLoadedWaivedContracts(true);
      return league;
    } else {
      loadWaivedContracts(league, leagues, dispatch,
        setIsLoadingWaivedContracts, setIsLoadedWaivedContracts, dataLoad.setIsException);
      return undefined;
    }
  }
  
  //////////////////////////////////////
  // LOAD SCHEDULE INFO
  //////////////////////////////////////
  if (!isLoadedScheduleData) {
    if (isLoadingScheduleData) {
      return undefined;
    } else if (!dataLoad.loadScheduleData) {
      setIsLoadedScheduleData(true);
    } else if (league?.currentSeason?.scheduleLoaded) {
      setIsLoadingScheduleData(false);
      setIsLoadedScheduleData(true);
      return undefined;
    } else {
      loadSchedule(league, leagues, dispatch, setIsLoadingScheduleData, setIsLoadedScheduleData, dataLoad.setIsException);
    }
  }
    
  // if Everything is loaded then return the league
  console.log("Setting League Data Load Complete");
  dataLoad.setLoadComplete(true);
  console.log(league);
  return league;
}

export function loadLeagueData(leagueId: String | undefined,
  userId: String | undefined,
  leagues: League[],
  loadContract: boolean,
  loadRoster: boolean,
  dispatch,
  setIsLoadingLeague : (isLoadingLeague: boolean) => void,
  setIsLoadedLeague : (isLoadedLeague: boolean) => void,
  setIsException : (isException: boolean) => void) {
    console.log("Loading League Data...");
    setIsLoadingLeague(true);

    leagueApi.getLeague(leagueId, true, userId, loadContract, loadRoster).then((resp) => {
      // Find the league, add it to the list of leagues, and update the state
      var leagueResp: League = resp.data;
      leagues = replaceLeague(leagueResp, leagues);
      dispatch(setLeagueDataLoad(leagues));
      // Set the loading flags
      setIsLoadingLeague(false);
      setIsLoadedLeague(true);
    }).catch((err) => {
      setIsException(true);
      throw err;
    });
}

export function loadSeasonData(league: League | undefined,
  leagues: League[],
  loadBasicDraftData: boolean,
  loadBasicAuctionData: boolean,
  dispatch,
  setIsLoadingSeason : (isLoadingSeason: boolean) => void,
  setIsLoadedSeason : (isLoadedSeason: boolean) => void,
  setIsException : (isException: boolean) => void) {
    console.log("Loading Season Data...");
    setIsLoadingSeason(true);
    // Note we are asking for draft data here but that does not guarantee that we will get it. If the draft has not started yet then we will not get draft data.
    leagueSeasonApi.getCurrentSeason(league?.id, loadBasicDraftData, loadBasicAuctionData).then((resp) => {
      // Update the league with the new season data
      var updatedLeague: League = {...league as League, currentSeason: resp.data};
      // Update the league in the state
      leagues = replaceLeague(updatedLeague, leagues);
      dispatch(setLeagueDataLoad(leagues));
      // Set the loading flags
      setIsLoadingSeason(false);
      setIsLoadedSeason(true);
    }).catch((err) => {
      setIsException(true);
      throw err;
    });
}




export function loadFullDraftData(league: League | undefined,
  leagues: League[],
  dispatch,
  setIsLoadingFullDraft : (isLoadingFullDraft: boolean) => void,
  setIsLoadedFullDraft : (isLoadedFullDraft: boolean) => void,
  setIsException : (isException: boolean) => void) {
    console.log("Loading Draft Data...");

    setIsLoadingFullDraft(true);

    
    leagueEventApi.getLeagueEvent(league?.currentSeason?.draft?.id).then((resp) => {
      // Update the league with the new season data
      var updatedSeason: LeagueSeason = {...league?.currentSeason as LeagueSeason, draft : resp.data};
      var updatedLeague: League = {...league as League, currentSeason: updatedSeason};
      // Update the league in the state
      leagues = replaceLeague(updatedLeague, leagues);
      dispatch(setLeagueDataLoad(leagues));
      // Set the loading flags
      setIsLoadingFullDraft(false);
      setIsLoadedFullDraft(true);
    }).catch((err) => {
      setIsException(true);
      throw err;
    });
}

export function loadWaivedContracts(league: League | undefined,
  leagues: League[],
  dispatch,
  setIsLoadingWaivedContracts : (isLoadingWaivedContracts: boolean) => void,
  setIsLoadedWaivedContracts : (isLoadedWaivedContracts: boolean) => void,
  setIsException : (isException: boolean) => void) {
    console.log("Loading Waived Contracts...");

    setIsLoadingWaivedContracts(true);

    
    contractApi.getContractsInStatus(league?.id, ContractStatus.Waived).then(response => {
      var updatedLeague: League = {...league as League, waivedContracts: response.data, waivedContractsLoaded: true};
      // Update the league in the state
      leagues = replaceLeague(updatedLeague, leagues);
      dispatch(setLeagueDataLoad(leagues));
      // Set the loading flags
      setIsLoadingWaivedContracts(false);
      setIsLoadedWaivedContracts(true);
    }).catch((err) => {
      setIsException(true);
      throw err;
    });
}

export function loadSchedule(league: League | undefined,
  leagues: League[],
  dispatch,
  setIsLoadingScheduleData : (isLoadingScheduleData: boolean) => void,
  setIsLoadedScheduleData : (isLoadedScheduleData: boolean) => void,
  setIsException : (isException: boolean) => void) {
    console.log("Loading League Schedule...");

    setIsLoadingScheduleData(true);

    
    leagueApi.getScheduleData(league?.id, league?.currentSeasonYear).then(response => {
      var updatedLeague: League = {...league as League, currentSeason: {...league!.currentSeason, schedule: response.data, scheduleLoaded: true}};
      // Update the league in the state
      leagues = replaceLeague(updatedLeague, leagues);
      dispatch(setLeagueDataLoad(leagues));
    }).catch((err) => {
      setIsException(true);
      throw err;
    });
}


// TODO: If this gets called a bunch (which it probably will) it will spam the backend with requests
// Note: This is not a hook just a function that is used to update the league data
export function useUpdateLeagueDataFromMessage(lastMessage) {

  // Navigator/Dispatcher
  const dispatch = useAppDispatch();

  // State
  var leagues: League[] = useAppSelector((state) => state.leagueDataLoad);

  var leagueData: League = {teams: []};

  // Read message and update league data (or do nothing if not a league message)
  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if("message" in data){
        console.log("Got message: ", lastMessage.data.message);
        // May need to do processing here or maybe some decision
       } if ("league" in data) {
          console.log("Got data: ", lastMessage.data.substring(0, Math.min(30,lastMessage.data.length)))
          leagueData = data.league as League;
       } else {
          console.log("No league data found in message, skipping...");
          return;
       }
    } else {
      return;
    }
    console.log("Updating league data with new data: " + JSON.stringify(leagueData));

    var league: League | undefined = findLeague(leagueData!.id! , leagues);
    
    console.log("Updating league data for league: " + leagueData!.id);
    console.log ("Old League Data: " + JSON.stringify(league));
    console.log("New League Data: " + JSON.stringify(leagueData));

    // Merge old league data with new league data
    // More info on the spread command found here (https://www.w3schools.com/react/react_es6_spread.asp)
    league = mergeLeagueData(league!, leagueData);

    dispatch(setLeagueDataLoad(replaceLeague(league, leagues)));

    console.log("Merged League Data: " + JSON.stringify(league));

  }, [lastMessage]);
}

export function useUpdateLeagueData(newLeagueData) {
  const dispatch = useAppDispatch();
  var leagues: League[] = useAppSelector((state) => state.leagueDataLoad);
  
  if (!newLeagueData) {
    return
  }
  
  dispatch(setLeagueDataLoad(replaceLeague(newLeagueData, leagues)))
}

export function useUpdateTradeDataFromMessage(lastMessage, args) {
  const dispatch = useAppDispatch();
  
  var trades: Map<string, TradeProposal[]> = useAppSelector((state) => state.tradeDataLoad);
  
  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      const tradeDataLoad = getUpdatedTradeDataLoad(data.tradeProposal, trades, args[0], data.tradeId);
      dispatch(tradeDataLoad);
    }
  }, [lastMessage])
}

export function useUpdatePlayerDataFromMessage(lastMessage, args) {
  const dispatch = useAppDispatch();
  
  var playerDataLoad: NflTeam[] = useAppSelector((state) => state.playerDataLoad );
  
  React.useEffect(() => {
    if (lastMessage) {
      const playerData = playerDataLoad.map(team => {
        const teamCopy = {...team};
        teamCopy.players = teamCopy.players?.map(player => {
          const playerCopy = {...player};
          playerCopy.quick_stats = {...player.quick_stats};
          return playerCopy;
        })
        return teamCopy
      });
      const playerMap = new Map(playerData.flatMap(team => team.players).map((player) => [player!.id, player]));
      const data = JSON.parse(lastMessage.data);
      Object.keys(data).forEach(playerId => {
        playerMap.get(playerId)!.quick_stats!.currentWeeksStats! = data[playerId];
      })
      dispatch(setPlayerDataLoad(playerData))
    }
  }, [lastMessage])
}

export function useUpdateNflGameDataFromMessage(lastMessage, args) {
  const dispatch = useAppDispatch();
  
  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      dispatch(setNflGameDataLoad(data))
    }
  }, [lastMessage])
}

export function useUpdatePlayerClaimDataFromMessage(lastMessage, args) {
  const dispatch = useAppDispatch();
  
  var teamPlayerClaims: Map<string, TradeProposal[]> = useAppSelector((state) => state.playerClaimDataLoad);
  
  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      const playerClaimDataLoad = getUpdatedPlayerClaimDataLoad(data.playerClaims, teamPlayerClaims, args[0], data.playerClaimsId);
      dispatch(playerClaimDataLoad);
    }
  }, [lastMessage])
}

export function useUpdatePlayerPoachDataFromMessage(lastMessage, args) {
  const dispatch = useAppDispatch();
  
  var teamPlayerPoachInfo: Map<string, PlayerPoachInfo[]> = useAppSelector((state) => state.playerPoachDataLoad);
  
  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      const playerPoachDataLoad = getUpdatedPlayerPoachDataLoad(data.playerPoachInfo, teamPlayerPoachInfo, args[0]);
      dispatch(playerPoachDataLoad);
    }
  }, [lastMessage])
}

export function findLeague(leagueId: String, leagues: League[]) {
  var index = leagues.findIndex((league) => league.id == leagueId);
  if(index == -1) {
    return  undefined
  }
  return leagues[index];
}

export function replaceLeague(L: League, leagues: League[]) {
  
  if(!leagues.map((league) => league.id).includes(L.id)) {
    return [...leagues, L];
  }
   var updatedLeagues =leagues.map((league) => {
    if (league.id == L.id) {
      return L;
    }
    return league;
  });
  return updatedLeagues;
}

// This is a function we will have to make sure it up to date and working correctly
export function mergeLeagueData(previousLeagueData: League, newLeagueData: League) {
  const options = {
    customMerge: (key) => {
      if (key === 'teams') {
        return mergeTeamsArray
      }
      
    },
    // By default we are just going to overwrite arrays with the new data
    arrayMerge: (destinationArray, sourceArray, options) => sourceArray
  }
  return merge(previousLeagueData, newLeagueData, options);
}

interface TradeDataLoad {
  teamId: string | undefined,
  setIsException: (isException: boolean) => void,
  isLoadComplete: boolean,
  setLoadComplete: (isLoadComplete: boolean) => void
}

export function useLoadTradeData(dataLoad: TradeDataLoad) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  
  var trades = useAppSelector((state) => state.tradeDataLoad);
  
  if (isLoading || !dataLoad.teamId) {
    return [];
  }
  const teamTrades : Array<TradeProposal> | undefined = trades.get(dataLoad.teamId);
  if(dataLoad.isLoadComplete) {
    return teamTrades || [];
  }
  if (teamTrades) {
    dataLoad.setLoadComplete(true);
    return teamTrades;
  }
  if (isLoaded) {
    dataLoad.setLoadComplete(true);
    return teamTrades || [];
  }
  
  setIsLoading(true);
  
  tradeApi.getTradeProposalsForTeam(dataLoad.teamId).then(response => {
    const newTrades = replaceTrades(dataLoad.teamId!, response.data, trades);
    dispatch(setTradeDataLoad(newTrades));
    setIsLoading(false);
    setIsLoaded(true);
  }).catch((err) => {
    setIsLoading(false); 
    setIsLoaded(true);
    dataLoad.setIsException(true);
    throw err;
  });
  
  return teamTrades || [];
}

function replaceTrades(teamId: string, teamTrades: Array<TradeProposal>, trades: Map<string, Array<TradeProposal>>) : Map<string, TradeProposal[]> {
  const newTrades = new Map(trades);
  newTrades.set(teamId, teamTrades);
  return newTrades;
}

export function useAddUpdateTrade(teamId: string, tradeId: string | undefined, trade: TradeProposal | null | undefined) {
  const dispatch = useAppDispatch();
  
  var trades = useAppSelector((state) => state.tradeDataLoad);
  
  if (!trade) {
    return;
  }
  
  const tradeDataLoad = getUpdatedTradeDataLoad(trade, trades, teamId, tradeId);
  dispatch(tradeDataLoad);
}

export function useAddUpdateTrades(teamId: string, updatedTrades: TradeProposal[]) {
  const dispatch = useAppDispatch();
  
  var trades = useAppSelector((state) => state.tradeDataLoad);
  
  if (!updatedTrades.length) {
    return;
  }
  
  const newTrades = new Map(trades);
  const tradesForTeam = newTrades.get(teamId);
  let newTradesForTeam : TradeProposal[];
  if (tradesForTeam) {
    newTradesForTeam = [...tradesForTeam];
    updatedTrades.forEach(trade => {
      removeTrade(newTradesForTeam, trade.id);
      newTradesForTeam.push(trade);
    });
  } else {
    newTradesForTeam = updatedTrades;
  }
  newTrades.set(teamId, newTradesForTeam);
  dispatch(setTradeDataLoad(newTrades));
}

function getUpdatedTradeDataLoad(trade: TradeProposal | null | undefined, trades: Map<string, TradeProposal[]>, teamId: string, tradeId: string | undefined) {
  const newTrades = new Map(trades);
  const tradesForTeam = newTrades.get(teamId);
  let newTradesForTeam : TradeProposal[] = [];
  if (tradesForTeam) {
    newTradesForTeam = [...tradesForTeam];
    removeTrade(newTradesForTeam, tradeId);
  }
  if (trade) {
    newTradesForTeam.push(trade);
  }
  newTrades.set(teamId, newTradesForTeam);
  return setTradeDataLoad(newTrades);
}

function removeTrade(newTradesForTeam, tradeId) {
   const tradeIndex = newTradesForTeam.findIndex(trade => trade.id === tradeId);
    if (~tradeIndex) {
      newTradesForTeam.splice(tradeIndex, 1);
    }
}

interface PlayerClaimDataLoad {
  teamId: string,
  setIsException: (isException: boolean) => void,
  isLoadComplete: boolean,
  setLoadComplete: (isLoadComplete: boolean) => void
}

export function useLoadPlayerClaimData(dataLoad: PlayerClaimDataLoad) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  
  var playerClaims = useAppSelector((state) => state.playerClaimDataLoad);
  
  if (isLoading) {
    return [];
  }
  const teamPlayerClaims : Array<TeamPlayerClaims> | undefined = playerClaims.get(dataLoad.teamId);
  if(dataLoad.isLoadComplete) {
    return teamPlayerClaims || [];
  }
  if (teamPlayerClaims) {
    dataLoad.setLoadComplete(true);
    return teamPlayerClaims;
  }
  if (isLoaded) {
    dataLoad.setLoadComplete(true);
    return teamPlayerClaims || [];
  }
  
  setIsLoading(true);
  
  playerClaimApi.getPlayerClaims(dataLoad.teamId).then(response => {
    const newPlayerClaims = replacePlayerClaims(dataLoad.teamId, response.data, playerClaims);
    dispatch(setPlayerClaimDataLoad(newPlayerClaims));
    setIsLoading(false);
    setIsLoaded(true);
  }).catch((err) => {
    setIsLoading(false); 
    setIsLoaded(true);
    dataLoad.setIsException(true);
    throw err;
  });
  
  return teamPlayerClaims || [];
}

function replacePlayerClaims(teamId: string, teamPlayerClaims: Array<TeamPlayerClaims>, playerClaims: Map<string, Array<TeamPlayerClaims>>) : Map<string, TeamPlayerClaims[]> {
  const newPlayerClaims = new Map(playerClaims);
  newPlayerClaims.set(teamId, teamPlayerClaims);
  return newPlayerClaims;
}

export function useAddUpdatePlayerClaim(teamId: string, teamPlayerClaimsId: string | undefined, teamPlayerClaims: TeamPlayerClaims | null | undefined) {
  const dispatch = useAppDispatch();
  
  var playerClaims = useAppSelector((state) => state.playerClaimDataLoad);
  
  if (!teamPlayerClaims) {
    return;
  }
  
  const playerClaimDataLoad = getUpdatedPlayerClaimDataLoad(teamPlayerClaims, playerClaims, teamId, teamPlayerClaimsId);
  dispatch(playerClaimDataLoad);
}

function getUpdatedPlayerClaimDataLoad(teamPlayerClaims: TeamPlayerClaims | null | undefined, playerClaims: Map<string, TeamPlayerClaims[]>, teamId: string, teamPlayerClaimsId: string | undefined) {
  const newPlayerClaims = new Map(playerClaims);
  const playerClaimsForTeam = newPlayerClaims.get(teamId);
  let newPlayerClaimsForTeam : TeamPlayerClaims[] = [];
  if (playerClaimsForTeam) {
    newPlayerClaimsForTeam = [...playerClaimsForTeam];
    removePlayerClaims(newPlayerClaimsForTeam, teamPlayerClaimsId);
  }
  if (teamPlayerClaims) {
    newPlayerClaimsForTeam.push(teamPlayerClaims);
  }
  newPlayerClaims.set(teamId, newPlayerClaimsForTeam);
  return setPlayerClaimDataLoad(newPlayerClaims);
}

function getUpdatedPlayerPoachDataLoad(playerPoachInfo: PlayerPoachInfo[], teamPlayerPoachInfo: Map<string, PlayerPoachInfo[]>, teamId: string) {
  const newTeamPlayerPoachInfo = new Map(teamPlayerPoachInfo);
  newTeamPlayerPoachInfo.set(teamId, playerPoachInfo);
  return setPlayerPoachDataLoad(newTeamPlayerPoachInfo);
}

function removePlayerClaims(newPlayerClaimsForTeam, teamPlayerClaimsId) {
   const playerClaimIndex = newPlayerClaimsForTeam.findIndex(playerClaim => playerClaim.id === teamPlayerClaimsId);
    if (~playerClaimIndex) {
      newPlayerClaimsForTeam.splice(playerClaimIndex, 1);
    }
}

function mergeTeamsArray(teamArray1, teamArray2) {
  // Merge teams on ID
  let property = "id";
  const options = {
    customMerge: (key) => {
      if (key === 'owners') {
        return mergeOwnersArray
      }
      
    },
    // By default we are just going to overwrite arrays with the new data
    arrayMerge: (destinationArray, sourceArray, options) => sourceArray
  }
  return mergeArrayOnProperty(teamArray1, teamArray2, property, true, true, options);
}

function mergeOwnersArray(ownerArray1, ownerArray2) {
  let property = "id";
  return mergeArrayOnProperty(ownerArray1, ownerArray2, property, false, true);
}

/**
 * 
 * @param destArray 
 * @param sourceArray 
 * @param property 
 * @param appendDest
 * @param appendSource
 * @returns 
 */
function mergeArrayOnProperty(destArray, sourceArray, property, appendDest = true, appendSource = true, options) {
  let result = [];
  for (let dest of destArray) {
    for (let source of sourceArray) {
      if (dest[property] === source[property]) {
        result.push(merge(dest, source, options));
      } 
    }
  }

  if (appendDest) {
    for (let dest of destArray) {
      if (!result.some((obj) => obj[property] === dest[property])) {
        result.push(dest);
      }
    }
  }

  if(appendSource) {
    for (let source of sourceArray) {
      if (!result.some((obj) => obj[property] === source[property])) {
        result.push(source);
      }
    }
  }

  return result;
}
