import * as React from 'react';
import { Team, TradeProposalTeam, TradeProposalPlayer, Player, TradeProposalPick, DraftPick } from '../../../sdk/model';
import {TableContainer, Paper, Table, TableHead, TableRow, TableBody} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { displayDraftPick, displayDraftPickDetails } from '../../util/DraftPickDisplay';
import PlayerCardLink from '../../cards/playerCardLink';
import DraftPickCardLink from '../../cards/draftPickCardLink';
import TeamDisplay from '../team/TeamDisplay';

interface tradeAtAGlanceProps {
  leagueId: string,
  tradeStatus: string,
  teams: Array<TradeProposalTeam>,
  picks: Array<TradeProposalPick>,
  players: Array<TradeProposalPlayer>,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team | undefined>,
  pickMap: Map<string | undefined, DraftPick | undefined> | undefined
}

function TradeAtAGlance(props: tradeAtAGlanceProps) {
  function getPlayerDetails(tradedPlayer : TradeProposalPlayer, isReceiving: boolean) {
    const player = props.playerMap.get(tradedPlayer.playerId);
    return <React.Fragment key={player?.id}>
      <p><PlayerCardLink player={player} leagueId={props.leagueId} /></p>
      {isReceiving && tradedPlayer.removeFromIr && <p>(returned from IR)</p>}
    </React.Fragment>;
  }
  function getPickDetails(tradedPick : TradeProposalPick) {
    if (tradedPick.originalTeamId) {
      const originalTeam = props.teamMap.get(tradedPick.originalTeamId);
      return <p key={tradedPick.draftPickId}><DraftPickCardLink pick={undefined} pickId={tradedPick.draftPickId} leagueId={props.leagueId}>{displayDraftPickDetails(tradedPick.seasonYear, tradedPick.roundNumber, tradedPick.pickNumber, originalTeam)}</DraftPickCardLink></p>;
    } else if (props.pickMap) {
      const pick = props.pickMap.get(tradedPick?.draftPickId);
      const originalTeam = props.teamMap.get(pick?.originalTeamId);
      return <p key={pick?.id}><DraftPickCardLink pick={pick} pickId={pick?.id} leagueId={props.leagueId}>{displayDraftPick(pick, originalTeam)}</DraftPickCardLink></p>;
    }
    return "Error getting pick details"
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{fontSize : 20}}>
        <TableRow>
          <StyledTableCell>Team</StyledTableCell>
          {props.tradeStatus === 'DRAFT' ? null : <StyledTableCell>Status</StyledTableCell>}
          <StyledTableCell>Trade</StyledTableCell>
          <StyledTableCell>Receive</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.teams.filter(team => team).map(tradeTeam => {
          const team = props.teamMap.get(tradeTeam.teamId)!;
          const tradedPlayers = props.players.filter(player => player.fromTeamId === team.id && player.action === 'TRADE');
          const droppedPlayers =props.players.filter(player => player.fromTeamId === team.id && player.action === 'DROP')
          const tradedPicks = props.picks.filter(pick => pick.fromTeamId === team.id);
          const receivedPlayers = props.players.filter(player => player.toTeamId === team.id);
          const receivedPicks = props.picks.filter(pick => pick.toTeamId === team.id);
          return <TableRow key={team.id}>
            <StyledTableCell><TeamDisplay leagueId={props.leagueId} team={team} emphasized={false} /></StyledTableCell>
            {props.tradeStatus === 'DRAFT' ? null : <StyledTableCell>{tradeTeam.status}</StyledTableCell>}
            <StyledTableCell>
              {tradedPlayers.map(player => getPlayerDetails(player, false))}
              {droppedPlayers.map(player => getPlayerDetails(player, false))}
              {tradedPicks.map(pick => getPickDetails(pick))}
            </StyledTableCell>
            <StyledTableCell>
              {receivedPlayers.map(player => getPlayerDetails(player, true))}
              {receivedPicks.map(pick => getPickDetails(pick))}
            </StyledTableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
  </TableContainer>
}

export default TradeAtAGlance;
