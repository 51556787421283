import { createTheme, Grid, ThemeProvider, Typography, Tab, Tabs, Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { leagueEventApi, leagueEventQueueApi } from '../../../adapters/APIExporter';
import { useLoadLeagueData, useUpdateLeagueDataFromMessage, useLoadTradeData, useUpdateTradeDataFromMessage } from '../../../app/dataLoaderHooks';
import { useAppSelector } from '../../../app/hooks';
import { Properties } from '../../../Properties';
import { Draft, League, Team, TradeProposal } from '../../../sdk/model';
import SelectPlayer from '../../select';
import DraftBoard from './component/DraftBoard';
import CommishControls from './component/CommishControls';
import DraftHistory from './component/DraftHistory';
import DraftPickModal from './component/DraftPickModal';
import DraftTeamViewer from './component/DraftTeamViewer';
import DraftTimer from './component/DraftTimer';
import "./style.css";
import useWebSocketListener from '../../web-sockets/WebSocketListener';

function DraftRoom() {

  // Contains current league ID
  const {leagueId} = useParams();

  // Redux store variables
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  // Moved playerMap logic to here, we should not be double storing the data in the redux store
  let playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));

  // Websocket url
  const webSocketUrl = Properties.websocketsUrl + '/ws/draft/' + user.username + '/' + leagueId;
  const { lastMessage } = useWebSocket(webSocketUrl, {shouldReconnect: () => true});

  // In memory variables
  const [playerSelection, setPlayerSelection] = React.useState({});
  const [playerQueue, setPlayerQueue] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const [view, setView] = React.useState('players');

  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isTradeLoadComplete, setTradeLoadComplete] = React.useState(false); 
  const [scrollLock, setScrollLock] = React.useState(false);
  
  // League Information
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true, 
    loadFullDraftData: true, loadContractData: true, loadRosterData: true,
    setLoadComplete: setLoadComplete, isLoadComplete: isLoadComplete,
    setIsException: setIsException, isException: isException});
    
  useUpdateLeagueDataFromMessage(lastMessage);
  
  const playerNominated = new Audio("/assets/sound/player-nominated.wav");
  const smallNotification = new Audio("/assets/sound/small-notification.wav");
  const eventBegin = new Audio("/assets/sound/event-begin.wav");
  
  React.useEffect(() => {
    if (!scrollLock) {
      var objDiv = document.getElementById("historyBox")!;
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }
  }, [league?.currentSeason?.draft?.activityHistory]);
  
  const myTeam : Team | undefined = league?.teams?.find((team) => team.owners?.some(owner => owner.id === user.id));
  
  function playSound(sound) {
    sound.play().catch(error => console.log(error));
  }
  
  React.useEffect(() => {
    if (lastMessage?.data) {
      const data = JSON.parse(lastMessage?.data);
      if (data.message === 'PICK_MADE') {
        const draft = data.league.currentSeason.draft;
        if (draft.draftOrder[draft.currentRound - 1][draft.currentPick - 1] === myTeam.id) {
          playSound(playerNominated);
        } else {
          playSound(smallNotification);
        }
      } else if (data.message === 'STARTED' || data.message === 'RESUMED') {
        playSound(eventBegin);
      }
    }
  }, [lastMessage])
  
  const tradeWebSocketUrl = Properties.websocketsUrl + '/ws/trades/' + user.username + '/' + myTeam?.id;
  useWebSocketListener(tradeWebSocketUrl, useUpdateTradeDataFromMessage, [myTeam?.id]);
  
  const trades : Array<TradeProposal> = useLoadTradeData({teamId: myTeam?.id, setIsException: setIsException, isLoadComplete: isTradeLoadComplete, setLoadComplete: setTradeLoadComplete});
  const pendingTrades = trades.filter(trade => trade.status === 'PENDING').length;
  
  React.useEffect(() => {
    if (isLoadComplete) {
      leagueEventQueueApi.getPlayerQueue(league!.currentSeason!.draft!.id, myTeam?.id).then((response) => {
        setPlayerQueue(response.data);
      });
    }
  }, [isLoadComplete]);

  // Wait for our league data to load
  if (!isLoadComplete) {
	return <p>Loading...</p>
  }

  const draft : Draft = league!.currentSeason!.draft!;
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));

  console.log("Teams Map:")
  console.log(teamMap)
  
  // Weirdly the current OpenAPI Generator just does not support `allOf` although it is on the short term roadmap
  // https://github.com/OpenAPITools/openapi-generator/issues/10514
  // Since this is javascript and javascript don't give a shit we can just call variables that don't exist on the model and it works fine lol (just kinda confusing since we are using typing)
  const currentRound = draft.currentRound;
  const currentPick = draft.currentPick;

  const currentDraftPick = draft.draftPicks[currentRound - 1][currentPick - 1];
  const teamOnTheClock = teamMap.get(currentDraftPick.teamId);
  const myTurn = teamOnTheClock?.owners?.some(owner => owner.id === user.id);
  const unavailablePlayers = new Set(draft.draftPicks.flat().map(pick => pick.contract.playerId));
  const countdownKey = draft.status === 'PENDING' ? 'PRE_DRAFT' : currentDraftPick.id + draft.status + draft.nextPickDeadline + draft.countdownSecondsRemaining;
  const commish = league!.commissioners!.some(commish => commish.id === user.id);
  
  const makeDraftSelection = function() {
  	setErrors([]);
  	const payload = {roundNumber: currentRound, pickNumber: currentPick, playerId: Object.keys(playerSelection)[0]};
  	leagueEventApi.makeSelection(draft.id, payload).catch((error) => {
      let errors = [error.response.data.message];
      setErrors(errors);
  	});
  }
  
  const addToQueue = function() {
    const playerId = Object.keys(playerSelection)[0];
    var playerQueueCopy : Array<string> = [...playerQueue];
    playerQueueCopy.push(playerId);
    updatePlayerQueue(playerQueueCopy);
    setPlayerSelection({});
  }
  
  const removeFromQueue = function() {
    const playerId = Object.keys(playerSelection)[0];
    var playerQueueCopy : Array<string> = [...playerQueue];
    const index = playerQueueCopy.indexOf(playerId);
    if (index !== -1) {
      playerQueueCopy.splice(index, 1);
    }
    updatePlayerQueue(playerQueueCopy);
    setPlayerSelection({});
  }
  
  const updatePlayerQueue = function(newQueue) {
    leagueEventQueueApi.setPlayerQueue(draft.id, myTeam?.id, newQueue);
    setPlayerQueue(newQueue);
  }
  
  function handleScroll(e) {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    setScrollLock(offsetHeight + scrollTop < scrollHeight);
  }

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  return (
	<ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex', height: 160}}>
        {draft.status === 'COMPLETE' ?
          <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 26 }}>
            Congratulations! The draft is complete.
          </Typography>
        :
          <Box sx={{ width: 100, height: 100, display: 'inline-block'}}>
            <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 16 }}>
                {draft.status === 'PENDING' ? "Draft starts in:" : "Pick " + currentRound + "." + String(currentPick).padStart(2, '0')}
            </Typography>
            <DraftTimer draft={draft} pickId={countdownKey} secondsPerPick={draft.secondsPerPick} />
            <CommishControls leagueEventApi={leagueEventApi} draftStatus={draft.status} draftId={draft.id} commish={commish} />
          </Box>
        }
        <DraftPickModal leagueId={leagueId!} currentPick={currentPick} currentRound={currentRound} draft={draft} 
          playerMap={playerMap} teamMap={teamMap} currentSeasonYear={league?.currentSeasonYear!} />
      </Box>
      {Object.keys(playerSelection).length > 0 && <Box>
        <Button color="primary" variant="contained" onClick={makeDraftSelection} disabled={!myTurn || draft.status !== 'STARTED'}>Draft</Button>
        {playerQueue.includes(Object.keys(playerSelection)[0]) ?
          <Button color="secondary" variant="contained" onClick={removeFromQueue}>Remove from Queue</Button> :
          <Button color="secondary" variant="contained" onClick={addToQueue}>Add to Queue</Button>
        }
      </Box>}
      {errorInfo}
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={view} onChange={(event, value) => {
              setView(value)
              }} aria-label="Draft Tabs">
              <Tab label="Available Players" value="players" />
              <Tab label="My Queue" value="queue" />
              <Tab label="Draft Board" value="board" />
            </Tabs>
          </Box>
          {view === 'players' && <SelectPlayer rookieYear={league?.currentSeason?.seasonYear} playerSelection={playerSelection} 
              setPlayerSelection={setPlayerSelection} unavailablePlayers={unavailablePlayers} />
          }
          {view === 'queue' && <SelectPlayer queue={playerQueue} playerSelection={playerSelection} 
              setPlayerSelection={setPlayerSelection} unavailablePlayers={unavailablePlayers} setQueue={updatePlayerQueue} />
          }
          {view === 'board' && <Box sx={{ overflow: 'auto' }}><DraftBoard draft={draft} teamMap={teamMap} playerMap={playerMap} /></Box>}
        </Grid>
        <Grid item xs={12} md={3}>
          {pendingTrades && <Box>
            <br/>
            <Badge badgeContent={pendingTrades} color="primary" title="Pending trades">
              <Button className='capsized-button' onClick={() => window.open('/league/' + league?.id + '/team/' + myTeam?.id + '/trades', '_blank', 'noreferrer')}>
                Pending Trades!
              </Button>
            </Badge>
            <br/><br/>
          </Box>}
          <Box sx={{ height: '25%', overflow: 'auto' }} id='historyBox' onScroll={handleScroll}>
            <DraftHistory leagueId={league?.id!} activityHistory={draft.activityHistory} playerMap={playerMap} teamMap={teamMap} />
          </Box>
          <Box>
            <DraftTeamViewer leagueId={leagueId!} teams={league?.teams} currentSeasonYear={league?.currentSeasonYear} salaryCap={league?.currentSeason?.nflSeason?.salaryCap}
                  playerMap={playerMap} teamMap={teamMap} userId={user?.id} rosterSettings={league?.rosterSettings} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default DraftRoom;
