import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { Properties } from '../../../Properties';
import useWebSocket from 'react-use-websocket';
import { useLoadLeagueData, useUpdateLeagueDataFromMessage } from '../../../app/dataLoaderHooks';
import { Auction, League, Team } from '../../../sdk/model';
import { leagueEventApi, leagueEventQueueApi } from '../../../adapters/APIExporter';
import { createTheme, Grid, ThemeProvider, Typography, Tab, Tabs, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuctionBiddingModal from './component/AuctionBiddingModal';
import TeamOrderModal from './component/TeamOrderModal';
import DraftTimer from '../draft/component/DraftTimer';
import CommishControls from '../draft/component/CommishControls';
import SelectPlayer from '../../select';
import AuctionHistory from './component/AuctionHistory';
import AuctionBoard from './component/AuctionBoard';
import DraftTeamViewer from '../draft/component/DraftTeamViewer';
import { MINIMUM_ONE_YEAR_SALARY, getTotalAmountFromCurrentYear } from '../../util/ContractUtils';
import {getRemainingRosterSpots} from '../../util/TeamUtils';

function AuctionRoom() {

  // Contains current league ID
  const {leagueId} = useParams();

  // Redux store variables
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  // Moved playerMap logic to here, we should not be double storing the data in the redux store
  let playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));

  // Websocket url
  const webSocketUrl = Properties.websocketsUrl + '/ws/auction/' + user.username + '/' + leagueId;
  const { lastMessage } = useWebSocket(webSocketUrl, {shouldReconnect: () => true});

  // In memory variables
  const [playerSelection, setPlayerSelection] = React.useState({});
  const [playerQueue, setPlayerQueue] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const [view, setView] = React.useState('players');
  const [nominationYears, setNominationYears] = React.useState(1);
  const [halted, setHalted] = React.useState(false);

  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  // League Information
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicAuctionData: true, 
    loadFullAuctionData: true, loadContractData: true, loadRosterData: true,
    setLoadComplete: setLoadComplete, isLoadComplete: isLoadComplete,
    setIsException: setIsException, isException: isException});
    
  useUpdateLeagueDataFromMessage(lastMessage);
  
  const chaChing = new Audio("/assets/sound/cha-ching.mp3");
  const playerNominated = new Audio("/assets/sound/player-nominated.wav");
  const smallNotification = new Audio("/assets/sound/small-notification.wav");
  const eventBegin = new Audio("/assets/sound/event-begin.wav");
  
  function haltFor(millis) {
    setHalted(true);
    setTimeout(() => {
      setHalted(false);
    }, millis);
  }
  
  function playSound(sound) {
    sound.play().catch(error => console.log(error));
  }
  
  React.useEffect(() => {
    if (lastMessage?.data) {
      const data = JSON.parse(lastMessage?.data);
      if (data.message === 'BID_MADE') {
        playSound(smallNotification);
        haltFor(1000);
      } else if (data.message === 'PICK_MADE') {
        playSound(playerNominated);
        haltFor(2000);
      } else if (data.message === 'STARTED' || data.message === 'RESUMED') {
        playSound(eventBegin);
      } else if (data.message === 'BID_ACCEPTED') {
        playSound(chaChing);
      }
    }
  }, [lastMessage])
  
  const myTeam : Team | undefined = league?.teams?.find((team) => team.owners?.some(owner => owner.id === user.id));
  
  React.useEffect(() => {
    if (isLoadComplete) {
      leagueEventQueueApi.getPlayerQueue(league!.currentSeason!.auction!.id, myTeam?.id).then((response) => {
        setPlayerQueue(response.data);
      });
    }
  }, [isLoadComplete]);

  // Wait for our league data to load
  if (!isLoadComplete) {
	return <p>Loading...</p>
  }

  const auction : Auction = league!.currentSeason!.auction!;
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));

  console.log("Teams Map:")
  console.log(teamMap)
  
  const currentPick = auction.currentPick;

  const teamOnTheClock = teamMap.get(auction.draftOrder[0][auction.currentNominationIndex]);
  const myTurn = teamOnTheClock?.owners?.some(owner => owner.id === user.id);
  const unavailablePlayers = new Set(league?.teams.flatMap(team => {
    const playerRoster = team.playerRoster ? team.playerRoster : [];
    return Object.values(playerRoster).flat().filter(player => player).map(player => player.playerId);
  }));
  if (auction.currentNominatedPlayerId) {
    unavailablePlayers?.add(auction.currentNominatedPlayerId);
  }
  const countdownKey = auction.status === 'PENDING' ? 'PRE_DRAFT' : currentPick + "-" + auction.bidsForCurrentPlayer.length;
  const commish = league!.commissioners!.some(commish => commish.id === user.id);
  const teamsInNominationOrder = auction.draftOrder[0].map((teamId) => teamMap.get(teamId));
  const nominationTeamId = auction.draftOrder[0][auction.currentNominationIndex];
  const salaryCap = league?.currentSeason?.nflSeason?.salaryCap === undefined ? 0 : league?.currentSeason?.nflSeason?.salaryCap;
  
  function getMaxBid(team: Team): number {
    const currentSeasonSalary = team?.currentSeasonSalary === undefined ? 0 : team.currentSeasonSalary;
    return salaryCap - currentSeasonSalary;
  }
  
  const teamRemainingRosterSpotsMap : Map<string | undefined, number> = new Map(league?.teams?.map((team) => [team.id, getRemainingRosterSpots(team, league!)]))
  const myTeamRemainingRosterSpots = teamRemainingRosterSpotsMap.get(myTeam!.id)!;
  const teamMaxBidMap : Map<string | undefined, number> = new Map(league?.teams?.map((team) => [team.id, getMaxBid(team)]))
  const leadingBid = auction.bidsForCurrentPlayer[auction.bidsForCurrentPlayer.length - 1];
  const nominateDisabled = !myTurn || auction.currentNominatedPlayerId || auction.status !== 'STARTED';
  
  const makeNominationSelection = function() {
  	setErrors([]);
  	const payload = {
      pickNumber: currentPick, 
  	  playerId: Object.keys(playerSelection)[0],
  	  years: nominationYears,
  	  totalAmount: getTotalAmountFromCurrentYear(MINIMUM_ONE_YEAR_SALARY, nominationYears)
  	};
  	leagueEventApi.makeSelection(auction.id, payload).catch((error) => {
      let errors = [error.response.data.message];
      setErrors(errors);
  	});
  }
  
  const addToQueue = function() {
    const playerId = Object.keys(playerSelection)[0];
    var playerQueueCopy : Array<string> = [...playerQueue];
    playerQueueCopy.push(playerId);
    updatePlayerQueue(playerQueueCopy);
    setPlayerSelection({});
  }
  
  const removeFromQueue = function() {
    const playerId = Object.keys(playerSelection)[0];
    var playerQueueCopy : Array<string> = [...playerQueue];
    const index = playerQueueCopy.indexOf(playerId);
    if (index !== -1) {
      playerQueueCopy.splice(index, 1);
    }
    updatePlayerQueue(playerQueueCopy);
    setPlayerSelection({});
  }
  
  const updatePlayerQueue = function(newQueue) {
    leagueEventQueueApi.setPlayerQueue(auction.id, myTeam?.id, newQueue);
    setPlayerQueue(newQueue);
  }

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  return (
	<ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex', height: 200}}>
        {auction.status === 'COMPLETE' ?
          <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 26 }}>
            Congratulations! The auction is complete.
          </Typography>
        :
          <Box>
            <Box sx={{ width: 100, height: 100, display: 'inline-block'}}>
              <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 13 }}>
                  {auction.status === 'PENDING' ? "Auction starts in:" : "Pick " + currentPick}
              </Typography>
              <DraftTimer draft={auction} pickId={countdownKey} secondsPerPick={auction.currentNominatedPlayerId ? auction.secondsPerBid : auction.secondsPerNomination} />
              <CommishControls leagueEventApi={leagueEventApi} draftStatus={auction.status} draftId={auction.id} commish={commish} />
            </Box>
            <AuctionBiddingModal teamMap={teamMap} nominationTeamId={nominationTeamId} 
                leadingBid={leadingBid} selectedPlayer={playerMap.get(auction.currentNominatedPlayerId)} 
                currentSeason={league?.currentSeasonYear} remainingContractLengths={auction.remainingContractLengthsForTeams[myTeam?.id].lengthNumbers}
                maxBid={teamMaxBidMap.get(myTeam?.id)} disabled={auction.status !== 'STARTED' || halted || !auction.currentNominatedPlayerId || (leadingBid && leadingBid.teamId === myTeam?.id) || myTeamRemainingRosterSpots <= 0} auctionId={auction.id} teamId={myTeam?.id} />
          </Box>
        }
      </Box>
      {errorInfo}
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <TeamOrderModal teams={teamsInNominationOrder} turnIndex={auction.currentNominationIndex} salaryCap={league?.currentSeason?.nflSeason?.salaryCap} teamRemainingRosterSpotsMap={teamRemainingRosterSpotsMap} teamMaxBidMap={teamMaxBidMap} remainingContractLengthsForTeams={auction.remainingContractLengthsForTeams} connectedUserIds={new Set(auction.connectedUserIds)} />
        </Grid>
        <Grid item xs={12} md={7}>
          {Object.keys(playerSelection).length > 0 && <Box>
            <TextField name="years" label="Years: " value={nominationYears} type="number" disabled={nominateDisabled}
                InputProps={{inputProps: {min: 1, max: 4}}} onChange={event => setNominationYears(Number(event.target.value))} />
            <Button color="primary" variant="contained" onClick={makeNominationSelection} disabled={nominateDisabled || (nominationYears > 1 && auction.remainingContractLengthsForTeams[myTeam?.id].lengthNumbers[nominationYears] < 1)}>Nominate</Button>
            {playerQueue.includes(Object.keys(playerSelection)[0]) ?
              <Button color="secondary" variant="contained" onClick={removeFromQueue}>Remove from Queue</Button> :
              <Button color="secondary" variant="contained" onClick={addToQueue}>Add to Queue</Button>
            }
          </Box>}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={view} onChange={(event, value) => {
              setView(value)
              }} aria-label="Auction Tabs">
              <Tab label="Available Players" value="players" />
              <Tab label="My Queue" value="queue" />
              <Tab label="Auction Board" value="board" />
            </Tabs>
          </Box>
          {view === 'players' && <SelectPlayer playerSelection={playerSelection} 
              setPlayerSelection={setPlayerSelection} unavailablePlayers={unavailablePlayers} />
          }
          {view === 'queue' && <SelectPlayer queue={playerQueue} playerSelection={playerSelection} 
              setPlayerSelection={setPlayerSelection} unavailablePlayers={unavailablePlayers} setQueue={updatePlayerQueue} />
          }
          {view === 'board' && <AuctionBoard auction={auction} playerMap={playerMap} teams={teamsInNominationOrder} />}
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <AuctionHistory auction={auction} playerMap={playerMap} teamMap={teamMap} />
          </Box>
          <Box>
            <DraftTeamViewer teams={league?.teams} currentSeasonYear={league?.currentSeasonYear} salaryCap={league?.currentSeason?.nflSeason?.salaryCap}
                  playerMap={playerMap} teamMap={teamMap} userId={user?.id} rosterSettings={league?.rosterSettings} tradeDisabled={true} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default AuctionRoom;
