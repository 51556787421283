import * as React from 'react';
import { TeamPlayerClaims, Player } from '../../../sdk/model';
import {Box, Typography, IconButton} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import ClearIcon from '@mui/icons-material/Clear';
import PlayerCardLink from '../../cards/playerCardLink';
import { playerClaimApi } from '../../../adapters/APIExporter';

interface waiverClaimsViewProps {
  playerClaims: TeamPlayerClaims,
  playerMap: Map<string | undefined, Player | undefined>,
  leagueId: string,
  setNewTeamPlayerClaims: Function
}

function WaiverClaimsView(props: waiverClaimsViewProps) {
  const columns = [
    {
      accessorKey: 'priority',
      id: 'priority',
      header: 'Priority',
      minSize: 15,
      size: 15
    },
    {
      accessorKey: 'playerId',
      id: 'claimedPlayer',
      header: 'Claimed Player',
      minSize: 15,
      size: 15,
      Cell: ({ renderedCellValue, row }) => {
        const player = props.playerMap.get(renderedCellValue)!;
        return <PlayerCardLink player={player} leagueId={props.leagueId} />
      }
    },
    {
      accessorKey: 'dropPlayerIds',
      id: 'dropPlayers',
      header: 'Player(s) to be dropped',
      minSize: 15,
      size: 15,
      Cell: ({ renderedCellValue, row }) => {
        if (!renderedCellValue.length) {
          return 'None';
        }
        const players = renderedCellValue.map(playerId => props.playerMap.get(playerId)!);
        return players.map(player => <p key={player.id}>
          <PlayerCardLink  player={player} leagueId={props.leagueId} />
        </p>)
      }
    },
    {
      accessorKey: 'priority',
      id: 'actions',
      header: 'Actions',
      minSize: 15,
      size: 15,
      Cell: ({ renderedCellValue, row }) => <IconButton onClick={() => {
            var newPlayerClaims = {...props.playerClaims};
            var newWaiverClaims = [...props.playerClaims.waiverClaims!];
            newWaiverClaims.splice(row.index, 1);
            newPlayerClaims.waiverClaims = newWaiverClaims;
            playerClaimApi.updateTeamPlayerClaims(newPlayerClaims, newPlayerClaims.id);
            props.setNewTeamPlayerClaims(newPlayerClaims);
      }} title="Remove Claim">
        <ClearIcon />
      </IconButton>
    }
  ];
  const waiverData = props.playerClaims.waiverClaims?.map((claim, claimIndex) => {
    return {...claim, priority: claimIndex + 1};
  })!;
  if (waiverData.length === 0) {
    return null;
  }
  return <Box>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Waiver Claims:
    </Typography>
    <MaterialReactTable enableRowSelection={false} enableMultiRowSelection={false} 
      getRowId={(row) => row.id!} columns={columns} 
      data={waiverData} 
      enableRowOrdering={true} enableRowSorting={false}
      muiTableHeadCellProps={{
        sx: {
          "& .Mui-TableHeadCell-Content-Labels": {
            padding: "0px",
          },
          "& .MuiBox-root": {
            padding: "0px",
          },
        },
      }}
      muiTableBodyRowDragHandleProps={({ table }) => ({
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState();
          if (hoveredRow && draggingRow && draggingRow !== hoveredRow) {
            var newPlayerClaims = {...props.playerClaims};
            var newWaiverClaims = [...props.playerClaims.waiverClaims!];
            newWaiverClaims.splice(
              hoveredRow.index,
              0,
              newWaiverClaims.splice(draggingRow.index, 1)[0],
            );
            newPlayerClaims.waiverClaims = newWaiverClaims;
            playerClaimApi.updateTeamPlayerClaims(newPlayerClaims, newPlayerClaims.id);
            props.setNewTeamPlayerClaims(newPlayerClaims);
          }
        },
      })}

    />
  </Box>
}

export default WaiverClaimsView;
