import * as React from 'react';
import { WaiverFaabEvent, Player, Team, PlayerClaimResult, PlayerClaimResultResultEnum } from '../../../sdk/model';
import {Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableBody} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import PlayerCardLink from '../../cards/playerCardLink';
import { toSalary } from '../../util/SalaryDisplay';
import TeamDisplay from '../team/TeamDisplay';

interface waiverFaabEventQuickViewProps {
  event: WaiverFaabEvent,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team | undefined>
}

function WaiverFaabEventQuickView(props: waiverFaabEventQuickViewProps) {
  const waiverResults : PlayerClaimResult[] = props.event.waiverResults;
  const winningWaiverClaims = waiverResults.filter(result => result.result === PlayerClaimResultResultEnum.Success);
  const waiverPlayerResults = winningWaiverClaims.map(winningClaim => {
    return {
      playerId: winningClaim.bid!.playerId!,
      winningTeamId: winningClaim.teamId!,
      dropPlayerIds: winningClaim.bid!.dropPlayerIds,
      losingTeamIds: waiverResults.filter(result => 
          result.result !== PlayerClaimResultResultEnum.Success && 
          result.bid!.playerId === winningClaim.bid!.playerId
        ).map(result => result.teamId!)
    }
  });
  const faabResults : PlayerClaimResult[] = props.event.faabResults;
  const winningFaabClaims = faabResults.filter(result => result.result === PlayerClaimResultResultEnum.Success);
  const faabPlayerResults = winningFaabClaims.map(winningClaim => {
    return {
      playerId: winningClaim.bid!.playerId!,
      type: winningClaim.bid!.type,
      winningBid: {
        teamId: winningClaim.teamId!,
        bidAmount: winningClaim.bid!.bidAmount,
        dropPlayerIds: winningClaim.bid!.dropPlayerIds,
        practiceSquad: winningClaim.bid!.practiceSquad
      },
      losingBids: faabResults.filter(result => 
          result.result !== PlayerClaimResultResultEnum.Success && 
          result.bid!.playerId === winningClaim.bid!.playerId
        ).map(result => {
          return {
            teamId: result.teamId!,
            bidAmount: result.bid!.bidAmount
          }
        })
    }
  });
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return <Box>
    {waiverPlayerResults.length > 0 && <Box>
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Claimed on Waivers:
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{fontSize : 20}}>
            <TableRow>
              <StyledTableCell>Player</StyledTableCell>
              <StyledTableCell>Winning Team</StyledTableCell>
              <StyledTableCell>Dropped Player(s)</StyledTableCell>
              <StyledTableCell>Other Bidding Teams</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waiverPlayerResults.map(claimResult => {
              const player = props.playerMap.get(claimResult.playerId)!;
              const winningTeam = props.teamMap.get(claimResult.winningTeamId)!;
              return <TableRow key={claimResult.playerId}>
                <StyledTableCell><PlayerCardLink player={player} leagueId={props.event.leagueId} /></StyledTableCell>
                <StyledTableCell>{winningTeam.name}</StyledTableCell>
                <StyledTableCell>{claimResult.dropPlayerIds!.length === 0 ? "None" : claimResult.dropPlayerIds!.map(dropPlayerId => {
                  const dropPlayer = props.playerMap.get(dropPlayerId)!;
                  return <PlayerCardLink player={dropPlayer} leagueId={props.event.leagueId} />
                })}</StyledTableCell>
                <StyledTableCell>
                   {claimResult.losingTeamIds.length ? claimResult.losingTeamIds.map(teamId => {
                     const losingTeam = props.teamMap.get(teamId)!;
                     return <p key={teamId}>{losingTeam.name}</p>
                   }) : 'None'}
                </StyledTableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>}
    {props.event.waiverClearedPlayers.length > 0 && <Box>
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Cleared Waivers:
      </Typography>
      {props.event.waiverClearedPlayers.map(playerId => props.playerMap.get(playerId)!).map((player, playerIndex) =>
        <React.Fragment><PlayerCardLink key={player.id} player={player} leagueId={props.event.leagueId} />{playerIndex < props.event.waiverClearedPlayers.length - 1 && ","}</React.Fragment>
      )}
    </Box>}
    {faabPlayerResults.length > 0 && <Box>
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Claimed via FAAB:
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{fontSize : 20}}>
            <TableRow>
              <StyledTableCell>Player</StyledTableCell>
              <StyledTableCell>Winning Bid</StyledTableCell>
              <StyledTableCell>Dropped Player(s)</StyledTableCell>
              <StyledTableCell>Other Bids</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faabPlayerResults.map(claimResult => {
              const player = props.playerMap.get(claimResult.playerId)!;
              const winningTeam = props.teamMap.get(claimResult.winningBid.teamId)!;
              return <TableRow key={claimResult.playerId}>
                <StyledTableCell><PlayerCardLink player={player} leagueId={props.event.leagueId} />{claimResult.type === 'poach_claim' ? " *POACH*" : ""}</StyledTableCell>
                <StyledTableCell><TeamDisplay leagueId={props.event.leagueId} team={winningTeam} emphasized={false} /> - {toSalary(claimResult.winningBid.bidAmount)} {claimResult.winningBid.practiceSquad ? "(PS)" : ""}</StyledTableCell>
                <StyledTableCell>{claimResult.winningBid.dropPlayerIds!.length === 0 ? "None" : claimResult.winningBid.dropPlayerIds!.map(dropPlayerId => {
                  const dropPlayer = props.playerMap.get(dropPlayerId)!;
                  return <PlayerCardLink player={dropPlayer} leagueId={props.event.leagueId} />
                })}</StyledTableCell>
                <StyledTableCell>
                   {claimResult.losingBids.length ? claimResult.losingBids.map(losingBid => {
                     const losingTeam = props.teamMap.get(losingBid.teamId)!;
                     return <p key={losingBid.teamId}><TeamDisplay leagueId={props.event.leagueId} team={losingTeam} emphasized={false} /> - {toSalary(losingBid.bidAmount)}</p>
                   }) : 'None'}
                </StyledTableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>}
  </Box>
}

export default WaiverFaabEventQuickView;
