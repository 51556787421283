import * as React from 'react'
import { NflTeam, Player } from '../../sdk/model';
import { useAppSelector } from '../../app/hooks';

export function dataloadToPlayerDataFlat(playerDataLoad: NflTeam[]) {
  // console.log("Converting Data...")
  // console.log(playerDataLoad)
  // TODO: This is probably super inefficient, need to fix later
  var playerDataLoadClone = JSON.parse(JSON.stringify(playerDataLoad));

  let playerList : Player[] = []
  // Added ? for player Data Load Clone since it was coming back null 
  playerDataLoadClone?.forEach((team) => {
    team.players?.forEach((player) => {
      player.nfl_team = {market: team.market, name: team.name, alias: team.alias};
      playerList.push(player);
    });
  });
  return playerList;
}

export function usePlayerDataLoadFlat() {
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  return new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player?.id, player]));
}

export function isPlayerLocked(player : Player | undefined) {
  return isPlayerLockedAt(player, new Date());
}

export function isPlayerLockedAt(player : Player | undefined, dateTime : Date) {
  const startDateStr = player?.quick_stats?.currentWeeksStats?.startDateTime;
  return startDateStr && dateTime > new Date(startDateStr);
}

export function getNextDayStart() {
  return getDaysFromNow(1)
}

export function getDaysFromNow(days : number) {
  const nextDayStart = new Date(new Date().toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles', timeZoneName: 'short' } ))
  nextDayStart.setDate(nextDayStart.getDate() + days);
  return nextDayStart;
}

export function isPlayerGameToday(player : Player | undefined) {
  const nextDayStart = getNextDayStart();
  const startDateStr = player?.quick_stats?.currentWeeksStats?.startDateTime;
  if (!startDateStr) {
    return false
  }
  const startDate = new Date(startDateStr);
  return startDateStr && startDate > new Date() && nextDayStart > startDate;
}