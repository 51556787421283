import { Tooltip } from "@mui/material";
import { League, LeagueGame, Player, PlayerGameScore, RosterPositionEnum, ScoringSettings, Team, TeamPlayerInfo, NflGame } from "../../sdk/model";
import { getPlayerCurrentScoreFromStats } from "./ScoreService";
import { Box } from "@mui/system";

export function loadGameRosterFromQuickstats(league: League, game: LeagueGame, playerMap : Map<string | undefined, Player | undefined>, nflGameMap: Map<string, NflGame>) : LeagueGame {
  console.debug("Loading game roster from quickstats...")

  // Have to do a deep copy so the game actually changes
  const newGame = {...game};
  const homeTeam: Team = league?.teams.find(team => team.id === game.homeTeamId)!;
  const homeTeamRoster = homeTeam.playerRoster;
  newGame.homeTeamRoster = getGameRoster(homeTeamRoster, league, playerMap, nflGameMap);

  const awayTeam: Team = league?.teams.find(team => team.id === game.awayTeamId)!;
  const awayTeamRoster = awayTeam.playerRoster;
  newGame.awayTeamRoster = getGameRoster(awayTeamRoster, league, playerMap, nflGameMap);

  scoreGame(newGame);

  return newGame;
}

export function ScoreToolTip({ playerGameScore, children }: { playerGameScore: PlayerGameScore, children: React.ReactNode }) {
  const scoreBreakdown =  playerGameScore.totalScore === 0 ? "¯\\_(ツ)_/¯" : JSON.stringify(playerGameScore.scoreBreakdown, null, 2)
  return (
    <Tooltip title={<pre>{scoreBreakdown}</pre>}>
      <Box>{children}</Box>
    </Tooltip>
  );
}

/**
 * This only adds up the scores from the starters to get the total score for the team
 */
export function scoreGame(game: LeagueGame) {
  if (!game.homeTeamScore) {
    game.homeTeamScore = scoreRoster(game.homeTeamRoster, player => player.totalScore);
    game.homeTeamProjection = scoreRoster(game.homeTeamRoster, player => player.projectedScore);
  }
  if (!game.awayTeamScore) {
    game.awayTeamScore = scoreRoster(game.awayTeamRoster, player => player.totalScore);
    game.awayTeamProjection = scoreRoster(game.awayTeamRoster, player => player.projectedScore);
  }
  game.finalScore = false;
  game.winningTeamId = game.homeTeamScore > game.awayTeamScore ? game.homeTeamId : game.awayTeamId;
}

function scoreRoster(teamRoster: { [key: string]: PlayerGameScore[]; } | undefined, scoreFunction : Function) {
  if(!teamRoster) {
    return 0;
  }
  let score = 0;
  Object.keys(teamRoster).forEach((position) => {
    if(position === RosterPositionEnum.Bn) {
      return;
    }
    const players = teamRoster[position];
    players.forEach(player => {
      score += scoreFunction(player) || 0;
    });
  });
  return parseFloat(score.toFixed(2));
  
}



function getGameRoster(teamRoster: { [key: string]: TeamPlayerInfo[]; } | undefined, league: League,
      playerMap : Map<string | undefined, Player | undefined>, nflGameMap: Map<string, NflGame>): { [key: string]: PlayerGameScore[]; } | undefined {
  if(!teamRoster) {
    return undefined;
  }
  const gameRoster: { [key: string]: PlayerGameScore[]; } = {};
  Object.keys(teamRoster).forEach((position) => {
    const players = teamRoster[position];
    gameRoster[position] = players.map(player => {
      return playerToScore(player, league?.currentSeason?.scoringSettings!, playerMap, nflGameMap);
    });
  });
  return gameRoster;
}

function playerToScore(playerInfo: TeamPlayerInfo, scoringSettings: ScoringSettings, playerMap : Map<string | undefined, Player | undefined>, nflGameMap: Map<string, NflGame>): any {
  if(!playerInfo) {
    return {};
  }
  const player: Player = playerMap.get(playerInfo?.playerId)!;
  if(!player) {
    // TODO: If we start retiring players, we should handle this better
    console.error("Player not found for id: " + playerInfo?.playerId);
    return {};
  }
  return getPlayerCurrentScoreFromStats(player, scoringSettings, nflGameMap);
}