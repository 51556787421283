import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Player, Team, AuctionBid } from '../../../../sdk/model';
import { Box, Typography, Paper } from '@mui/material';
import ContractSummary from './ContractSummary';
import MinimumBidButton from './MinimumBidButton';
import CustomBidModal from './CustomBidModal';
import PlayerCardLink from '../../../cards/playerCardLink';
import { MINIMUM_BID_INCREMENT, MINIMUM_ONE_YEAR_SALARY, MINIMUM_BID_INCREASE_PCT } from '../../../util/ContractUtils';

interface auctionBiddingModalProps {
  selectedPlayer: Player | undefined,
  teamMap: Map<String | undefined, Team>,
  nominationTeamId: String | undefined,
  leadingBid: AuctionBid | undefined,
  currentSeason: number | undefined,
  remainingContractLengths: Map<number, number>,
  maxBid: number | undefined,
  disabled: boolean,
  auctionId: string,
  teamId: string | undefined
}

function AuctionBiddingModal(props: auctionBiddingModalProps) {
  const {leagueId} = useParams();
  const [leadingBid, setLeadingBid] = React.useState(props.leadingBid);
  
  React.useEffect(() => {
    setLeadingBid(props.leadingBid)
  }, [props.leadingBid])
  
  function getOneYearValue(years: number, totalAmount: number) {
    if (years === 1) {
      return totalAmount;
    }
    const factorReduction = ((years - 1) * 2.0 - 1) / 3.0;
    const divisionFactor = years - factorReduction;
    return totalAmount / divisionFactor;
  }
  
  const nominatingTeam = props.nominationTeamId ? props.teamMap.get(props.nominationTeamId) : null;
  const leadingBidTeam = leadingBid ? props.teamMap.get(leadingBid.teamId) : null;
  const years = leadingBid?.years ? leadingBid?.years : 0;
  const totalAmount = leadingBid?.totalAmount ? leadingBid?.totalAmount : MINIMUM_ONE_YEAR_SALARY;
  const oneYearValue = getOneYearValue(years, totalAmount);
  const minimumRaise = Math.round(Math.max(oneYearValue * MINIMUM_BID_INCREASE_PCT, MINIMUM_BID_INCREMENT) / MINIMUM_BID_INCREMENT) * MINIMUM_BID_INCREMENT; 
  const minimumOneYearOfferValue = Math.ceil(oneYearValue/MINIMUM_BID_INCREMENT) * MINIMUM_BID_INCREMENT + minimumRaise;
  
  return <Box sx={{ display: 'inline-block', whiteSpace: 'nowrap', overflowX: 'auto'}}>
    <Paper sx={{ float: 'left', width: 280, whiteSpace: 'normal', height: 160, margin: 2 }}>
      {props.selectedPlayer ?
        <Box>
          <Box>On the block: <PlayerCardLink player={props.selectedPlayer} leagueId={leagueId!} /></Box>
          <Box>{props.selectedPlayer.position} - {props.selectedPlayer.nfl_team ? props.selectedPlayer.nfl_team.alias : 'FA'}, Age: {props.selectedPlayer.ageYears}, Bye: {props.selectedPlayer.quick_stats?.byeWeek}</Box>
          <br/>
          <ContractSummary years={years} totalAmount={totalAmount} owningTeamName={leadingBidTeam?.name} currentSeason={props.currentSeason} />
        </Box> :
        <p>Waiting for {nominatingTeam?.name} to nominate a player</p>
      }
    </Paper>
    <Box sx={{ float: 'left' }}>
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Minimum Bids
      </Typography>
      {[...Array(4).keys()].map(i => i + 1).map(bidYears => 
        <MinimumBidButton years={bidYears} minimumOneYearOfferValue={minimumOneYearOfferValue} currentSeason={props.currentSeason} bidsRemaining={props.remainingContractLengths[bidYears]} maxBid={props.maxBid === undefined ? 0 : props.maxBid} disabled={props.disabled} auctionId={props.auctionId} teamId={props.teamId} />
      )}
    </Box>
    <CustomBidModal minimumOneYearOfferValue={minimumOneYearOfferValue} currentSeason={props.currentSeason} maxBid={props.maxBid === undefined ? 0 : props.maxBid} disabled={props.disabled} auctionId={props.auctionId} teamId={props.teamId} remainingContractLengths={props.remainingContractLengths} selectedPlayer={props.selectedPlayer} />
  </Box>
}

export default AuctionBiddingModal;