import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks'
import { League, Team, TradeProposal, TeamPlayerClaims, PlayerPoachInfo } from '../../../sdk/model';
import { Properties } from '../../../Properties';
import { useLoadTradeData, useUpdateTradeDataFromMessage, useUpdatePlayerClaimDataFromMessage, useUpdatePlayerPoachDataFromMessage, useLoadPlayerClaimData, useLoadPlayerPoachData } from '../../../app/dataLoaderHooks';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { List, ListItem, ListItemButton, ListItemText, Badge } from '@mui/material';
import useWebSocketListener from '../../web-sockets/WebSocketListener';

interface TeamViewData {
  league: League, 
  team: Team
}

function TeamView(props : TeamViewData) {
  const navigate = useNavigate();
  
  const [isTradeLoadComplete, setTradeLoadComplete] = React.useState(false);
  const [isPlayerClaimLoadComplete, setPlayerClaimLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  
  const user = useAppSelector((state) => state.user);
  
  const tradeWebSocketUrl = Properties.websocketsUrl + '/ws/trades/' + user.username + '/' + props.team.id;
  useWebSocketListener(tradeWebSocketUrl, useUpdateTradeDataFromMessage, [props.team.id!]);
  
  const claimsWebSocketUrl = Properties.websocketsUrl + '/ws/playerClaims/' + user.username + '/' + props.team.id;
  useWebSocketListener(claimsWebSocketUrl, useUpdatePlayerClaimDataFromMessage, [props.team.id!]);
  
  const poachWebSocketUrl = Properties.websocketsUrl + '/ws/playerPoachRequests/' + user.username + '/' + props.team.id;
  useWebSocketListener(poachWebSocketUrl, useUpdatePlayerPoachDataFromMessage, [props.team.id!]);

  const trades : Array<TradeProposal> = useLoadTradeData({teamId: props.team.id!, setIsException: setIsException, isLoadComplete: isTradeLoadComplete, setLoadComplete: setTradeLoadComplete});
  const pendingTrades = trades.filter(trade => trade.status === 'PENDING').length;
  
  const playerClaims : Array<TeamPlayerClaims> = useLoadPlayerClaimData({teamId: props.team.id!, setIsException: setIsException, isLoadComplete: isPlayerClaimLoadComplete, setLoadComplete: setPlayerClaimLoadComplete});
  const playerPoachInfo : Array<PlayerPoachInfo> | undefined = useAppSelector((state) => state.playerPoachDataLoad).get(props.team.id!);

  
  return <Box>
    <Divider />
    <List>
      <ListItem>
        {props.team.name}
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigate('/league/' + props.league.id + '/team/' + props.team.id)}>
          <ListItemText primary="Roster/Team Info" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigate('/league/' + props.league.id + '/team/' + props.team.id + '/trades')}>
          <Badge badgeContent={pendingTrades} color="primary" title="Pending trades">
            <ListItemText primary="Trades" />
          </Badge>
        </ListItemButton>
      </ListItem>
      {props.league.currentSeason?.currentWeek !== undefined && props.league.currentSeason?.currentWeek >= 5 && props.league.currentSeason?.currentWeek <= 14 &&
        <ListItem>
          <ListItemButton onClick={() => navigate('/league/' + props.league.id + '/team/' + props.team.id + '/midSeasonExtensions')}>
            <ListItemText primary="Mid-Season Extension Offers" />
          </ListItemButton>
        </ListItem>
      }
      <ListItem>
        <ListItemButton onClick={() => navigate('/league/' + props.league.id + '/team/' + props.team.id + '/playerClaims')}>
          <Badge badgeContent={playerClaims.reduce((partialSum, teamPlayerClaims) => partialSum + teamPlayerClaims.waiverClaims!.length + teamPlayerClaims.faabClaims!.length, 0)} color="primary" title="Pending player claims">
            <ListItemText primary="Player Claims" />
          </Badge>
        </ListItemButton>
      </ListItem>
      {playerPoachInfo !== undefined && playerPoachInfo.length > 0 &&
        <ListItem>
          <ListItemButton onClick={() => navigate('/league/' + props.league.id + '/team/' + props.team.id + '/poachOffers')}>
            <Badge badgeContent={playerPoachInfo.length} color="primary" title="Players Being Poached">
              <ListItemText primary="Active Poach Attempts" />
            </Badge>
          </ListItemButton>
        </ListItem>
      }
      <ListItem>
        <ListItemButton onClick={() => navigate('/league/' + props.league.id + '/schedule?teamId=' + props.team.id)}>
          <ListItemText primary="Team Schedule" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigate('/league/' + props.league.id + '/team/' + props.team.id + '/settings')}>
          <ListItemText primary="Team Settings" />
        </ListItemButton>
      </ListItem>
    </List>
  </Box>;
}

export default TeamView;
