import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid  from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { createTheme, Input, InputLabel, ThemeProvider, TextField, 
    Select, MenuItem, Checkbox, Button } from '@mui/material';
import { leagueApi } from '../../../adapters/APIExporter'
import RosterConfig from './commish/RosterConfig';

function CreateLeague() {
  const navigate = useNavigate();
  const [league, setLeague] = React.useState({
	leagueName : '',
    numberOfTeams : 12,
    format : 'HALF_FOUR',
    rosterSettings: {
  	  QB: 1,
      RB: 2,
      WR: 2,
      TE: 1,
      FLEX: 1,
      DEF: 0,
      K: 0,
      BN: 12,
      SFLEX: 0,
      P: 0,
      DL: 0,
      LB: 0,
      DB: 0,
      DFLEX: 0,
      IR: 2,
      PS: 0
    }
  });
  const [errors, setErrors] = React.useState({});
  function handleLeagueChange(event) {
    const {name, value} = event.target;
    let newLeague = {...league};
    newLeague[name] = value;
    setLeague(newLeague);
  }
  function handleRosterChange(event) {
    const {name, value} = event.target;
    let newLeague = {...league};
    newLeague.rosterSettings[name] = Number(value);
    setLeague(newLeague);
  }
  function handleSavePressed() {
	let errors = {};
    validateLeagueName(league.leagueName, errors);
    validateRosterSettings(league.rosterSettings, errors);
    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      return;
    }
    leagueApi.createLeague(league).then((response) => {
      navigate("/league/" + response.data);
  	}).catch((error) => {
      let errors = {serverError: error.response.data.message};
      setErrors(errors);
  	});
  }
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const minTeams = 4;
  const maxTeams = 32;
  const numberOfTeamsOptions = Array.from(
	  {length: maxTeams + 1 - minTeams}, 
      (_, i) => i + minTeams
  ).filter((number) => number % 2 === 0);

  let errorInfo = Object.entries(errors).map(([key, value]) => {
    if (value) {
      return (<p className='error' key={'error_' + key}>{value}</p>);
    }
    return (<p className='error' key={'error_' + key}></p>);
  });

  return (
	<ThemeProvider theme={darkTheme}>
	  <Grid className='component'>
	    <Box>
          <Typography
              component="h3"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom>
            League Setup:
          </Typography>
          <br/>
          <TextField name='leagueName' label="Enter League Name" value={league.leagueName}
              onChange={handleLeagueChange}/>
          <br/>
          <InputLabel id="numberOfTeamsLabel">Number of Teams</InputLabel>
          <Select name="numberOfTeams" label="Number of Teams" labelId="numberOfTeamsLabel" value={league.numberOfTeams}
              onChange={handleLeagueChange}>
            {
              Object.entries(numberOfTeamsOptions).map(([key, value]) => {
                return <MenuItem key={key} value={value}>{value}</MenuItem>
              })
            }
          </Select>
          <br/>
          <InputLabel id="scoringFormatLabel">Scoring Format</InputLabel>
          <Select name="format" label="Scoring Format" labelId="scoringFormatLabel" value={league.format}
              onChange={handleLeagueChange}>
            <MenuItem value='STANDARD_FOUR'>Standard (0 PPR) - 4 Pt Passing TDs</MenuItem>
            <MenuItem value='STANDARD_SIX'>Standard (0 PPR) - 6 Pt Passing TDs</MenuItem>
            <MenuItem value='HALF_FOUR'>Half (1/2 PPR) - 4 Pt Passing TDs</MenuItem>
            <MenuItem value='HALF_SIX'>Half (1/2 PPR) - 6 Pt Passing TDs</MenuItem>
            <MenuItem value='FULL_FOUR'>Full (1 PPR) - 4 Pt Passing TDs</MenuItem>
            <MenuItem value='FULL_SIX'>Full (1 PPR) - 6 Pt Passing TDs</MenuItem>
          </Select>
          <br/>
          <Typography
              component="h3"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom>
            Roster Configuration:
          </Typography>
          <br/>
          <RosterConfig rosterSettings={league.rosterSettings} handleRosterChange={handleRosterChange} />
          <Button className='capsized-button' variant="contained" onClick={() => handleSavePressed()}>Save</Button> 
          {errorInfo}
        </Box>
      </Grid>
    </ThemeProvider>
  )
}

const validateLeagueName = (value, errors) => {
  if (value.length < 3 || value.length > 50) {
	errors.leagueName = 'You must provide a League Name with a length between 3 and 50 characters';
  }
}

const validateRosterSettings = (rosterSettings, errors) => {
  if (Object.entries(rosterSettings).every(([key, value]) => key === 'BN' || value === 0)) {
	errors.rosterSettings = "You must have at least one active position on your league's rosters" ;
  }
}

export default CreateLeague;