import { Box, Typography, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import * as React from 'react';
import { Player, Team, TradeProposalPlayer, TradeProposalPick } from '../../../sdk/model';
import RosterContractDisplay from '../team/RosterContractDisplay';
import { displayDraftPick } from '../../util/DraftPickDisplay';
import TradeOptionsMenu from './TradeOptionsMenu';
import TeamDraftPickDisplay from '../team/TeamDraftPickDisplay';

interface teamViewerProps {
  team: Team | undefined,
  userId: string | undefined,
  currentSeason: number,
  playerMap: Map<string | undefined, Player | undefined>,
  proposalPlayerMap: Map<string | undefined, TradeProposalPlayer | undefined>,
  proposalPickMap: Map<string | undefined, TradeProposalPick | undefined> | undefined,
  teamMap: Map<string | undefined, Team>,
  myTeam: boolean,
  otherTeamIds: Array<string | undefined>,
  handleTradeProposalChange: Function,
  earliestLockDate: Date | null,
  practiceSquad: boolean
}

function TradeTeamViewer(props: teamViewerProps) {
  return <Box sx={{ height: '50%', marginLeft: 3, marginRight: 3 }}>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      {props.team?.name}
    </Typography>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Players
    </Typography>
    <RosterContractDisplay team={props.team!} myTeam={props.myTeam} currentSeason={props.currentSeason} otherTeamIds={props.otherTeamIds}
        teamMap={props.teamMap} proposalPlayerMap={props.proposalPlayerMap} playerMap={props.playerMap}
        handleTradeProposalChange={props.handleTradeProposalChange} earliestLockDate={props.earliestLockDate} practiceSquad={props.practiceSquad} />
    {props.proposalPickMap && <TeamDraftPickDisplay team={props.team} teamMap={props.teamMap} proposalPickMap={props.proposalPickMap} handleTradeProposalChange={props.handleTradeProposalChange} myTeam={props.myTeam} otherTeamIds={props.otherTeamIds} currentSeason={props.currentSeason} />}
  </Box>
}

export default TradeTeamViewer;