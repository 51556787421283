import { RosterPositionEnum } from "../../sdk/model";

// This is the default order of positions in a roster

export const positionOrder: Array<RosterPositionEnum> = [
  RosterPositionEnum.Qb,
  RosterPositionEnum.Rb,
  RosterPositionEnum.Wr,
  RosterPositionEnum.Te,
  RosterPositionEnum.Sflex,
  RosterPositionEnum.Flex,
  RosterPositionEnum.Def,
  RosterPositionEnum.K,
  RosterPositionEnum.P,
  RosterPositionEnum.Dl,
  RosterPositionEnum.Lb,
  RosterPositionEnum.Db,
  RosterPositionEnum.Dflex,
  RosterPositionEnum.Bn,
  RosterPositionEnum.Ir
];


export const positionOrderNoBench: Array<RosterPositionEnum> = [
  RosterPositionEnum.Qb,
  RosterPositionEnum.Rb,
  RosterPositionEnum.Wr,
  RosterPositionEnum.Te,
  RosterPositionEnum.Sflex,
  RosterPositionEnum.Flex,
  RosterPositionEnum.Def,
  RosterPositionEnum.K,
  RosterPositionEnum.P,
  RosterPositionEnum.Dl,
  RosterPositionEnum.Lb,
  RosterPositionEnum.Db,
  RosterPositionEnum.Dflex,
];
