import * as React from 'react';
import { Team, TradeProposalPlayer, Player, RosterPositionEnum } from '../../../sdk/model';
import {Box, Typography, FormGroup, FormControlLabel, Checkbox} from '@mui/material';

interface returnFromIrTradeOptionProps {
  players : TradeProposalPlayer[] | undefined,
  allInvolvedTeams : (Team | undefined)[],
  setPlayers : Function,
  playerMap: Map<string | undefined, Player | undefined>,
  myTeam: Team
}

function ReturnFromIrTradeOption(props: returnFromIrTradeOptionProps) {
   const irPlayers = props.allInvolvedTeams
        .filter(team => team)
        .flatMap(team => team.playerRoster[RosterPositionEnum.Ir]) || [];
   const irPlayersToMyTeam = props.players
    .filter(player => player.toTeamId === props.myTeam.id &&
        irPlayers.some(teamPlayer => teamPlayer && teamPlayer.playerId === player.playerId)
    );
    
    if (!irPlayersToMyTeam.length) {
      return null;
    }
    return <Box >
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Would you like to immediately return the following player(s) from IR as part of this trade?
      </Typography>
      <FormGroup sx={{alignContent: 'center'}}>
        {irPlayersToMyTeam.map(irPlayer => 
          <FormControlLabel key={irPlayer.playerId} control={<Checkbox checked={irPlayer.removeFromIr} 
              onChange={() => {
                  const newPlayers = props.players!.map(player => {
                    return {...player}
                  });
                  const newIrPlayer = newPlayers.find(player => player.playerId === irPlayer.playerId)!;
                  newIrPlayer.removeFromIr = !newIrPlayer.removeFromIr;
                  props.setPlayers(newPlayers);
              }} />} label={props.playerMap.get(irPlayer.playerId)?.abbr_name} />
        )}
      </FormGroup>
    </Box>
}

export default ReturnFromIrTradeOption;