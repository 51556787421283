import * as React from 'react';
import { PlayerQuickSignEvent, Player, Team } from '../../../sdk/model';
import {Typography, Box} from '@mui/material';
import PlayerCardLink from '../../cards/playerCardLink';
import TeamDisplay from '../team/TeamDisplay';

interface quickSignDisplayProps {
  event: PlayerQuickSignEvent,
  playerMap: Map<String | undefined, Player | undefined>,
  teamMap: Map<String | undefined, Team>
}

function quickSignDisplay(props: quickSignDisplayProps) {
  const player = props.playerMap.get(props.event.quickSign.playerId)!;
  const dropPlayerIds = props.event.quickSign.dropPlayerIds
  return <Typography>
    <PlayerCardLink player={player} leagueId={props.event.leagueId} /> was signed by <TeamDisplay leagueId={props.event.leagueId} team={props.teamMap.get(props.event.teamId)!} emphasized={false} /> to a league-minimum deal.
    {dropPlayerIds.length ? <Box>
        Dropped: {dropPlayerIds.map((dropPlayerId, dropPlayerIndex) => {
        const droppedPlayer = props.playerMap.get(dropPlayerId)!;
        return <React.Fragment><PlayerCardLink key={dropPlayerId} player={droppedPlayer} leagueId={props.event.leagueId}/>{dropPlayerIndex === dropPlayerIds.length - 1 || ", "}</React.Fragment>
      })}</Box> : "  No Dropped Players."
    }
  </Typography>
}

export default quickSignDisplay;
