import * as React from 'react';
import { useParams } from 'react-router-dom';
import { leagueSeasonApi, leagueEventApi } from '../../../adapters/APIExporter';
import { LeagueSeason, LeagueEvent, League } from '../../../sdk/model';
import {Box, Typography, InputLabel, Select, MenuItem} from '@mui/material';
import LeagueEventDisplay from '../leagueEvent/LeagueEventDisplay';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';

function LeagueTransactions() {
  const {leagueId} = useParams();
  const [seasons, setSeasons] = React.useState<LeagueSeason[]>([]);
  const [leagueSeason, setLeagueSeason] = React.useState<LeagueSeason | undefined>(undefined);
  const [weekNumber, setWeekNumber] = React.useState<number | undefined>(undefined);
  const [leagueEvents, setLeagueEvents] = React.useState<LeagueEvent[]>([]);
  const [eventsLoaded, setEventsLoaded] = React.useState<boolean>(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  
  React.useEffect(() => {
    leagueSeasonApi.getAllLeagueSeasons(leagueId).then(response => {
      const seasons : LeagueSeason[] = response.data;
      setSeasons(seasons);
      const currentSeason = seasons[seasons.length - 1];
      setLeagueSeason(currentSeason);
      setWeekNumber(currentSeason.currentWeek);
    });
  }, []);
  
  React.useEffect(() => {
    if (leagueSeason && weekNumber !== undefined) {
      setEventsLoaded(false);
      leagueEventApi.getLeagueEvents(leagueId, undefined, leagueSeason.seasonYear, weekNumber).then(response => {
        setLeagueEvents(response.data)
        setEventsLoaded(true);
      });
    }
  }, [weekNumber, leagueSeason])
  
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicAuctionData: false, 
    loadFullAuctionData: false, loadContractData: true, loadRosterData: true,
    setLoadComplete: setLoadComplete, isLoadComplete: isLoadComplete,
    setIsException: setIsException, isException: isException});

  if (!seasons.length || !league || !playerDataLoad) {
    return "Loading..."
  }
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player?.id, player]));
  const teamMap = new Map(league.teams!.map(team => [team.id, team]));
   
  return <Box>
    <Typography variant="h4" sx={{ flexGrow: 1 }}>
      League Transactions
    </Typography>
    <Box sx={{ display: 'inline-block' }}>
      <InputLabel id="seasonYearLabel">Season</InputLabel>
      <Select
        labelId="seasonYearLabel"
        id="seasonYear"
        value={leagueSeason}
        label="Age"
        onChange={event => setLeagueSeason(event.target.value)}
      >
        {seasons.map(season => 
          <MenuItem key={season.seasonYear} value={season}>{season.seasonYear}</MenuItem>
        )}
      </Select>
    </Box>
    <Box sx={{ display: 'inline-block' }}>
      <InputLabel id="weekNumberLabel">Week</InputLabel>
      <Select
        labelId="weekNumberLabel"
        id="weekNumber"
        value={weekNumber}
        label="Age"
        onChange={event => setWeekNumber(event.target.value)}
      >
         {[...Array(leagueSeason!.currentWeek! + 1).keys()].map(weekNumber => 
          <MenuItem key={weekNumber} value={weekNumber}>{weekNumber ? weekNumber : "Preseason"}</MenuItem>
        )}
      </Select>
    </Box>
    {eventsLoaded ?
      (leagueEvents.length ? leagueEvents.map((event, eventIndex) => 
        <LeagueEventDisplay key={eventIndex} event={event} playerMap={playerMap} teamMap={teamMap} />
      ) : <Typography>No Transactions</Typography>) : <Typography>Loading...</Typography>
    }
  </Box>
}

export default LeagueTransactions;