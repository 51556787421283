import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import {Box, Typography, Button, Dialog, DialogTitle, IconButton, DialogContent, DialogActions} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { League, Team, DraftPick, TeamPlayerInfo, TradeProposal, TradeAcceptancePayload, TradeProposalPlayer, RosterPositionEnum } from '../../../sdk/model';
import { tradeApi } from '../../../adapters/APIExporter';
import { findDefaultTeam } from '../draft/service';
import { useLoadLeagueData, useLoadTradeData } from '../../../app/dataLoaderHooks';
import TradeAtAGlance from './TradeAtAGlance';
import LeagueEventDatePicker from '../league/commish/LeagueEventDatePicker';
import TradeDetails from './TradeDetails';
import ReturnFromIrTradeOption from './ReturnFromIrTradeOption'
import { getNewSalary, getSalaryForSeason } from '../../util/SalaryDisplay';
import { getRosterSize, getMaxTeamSize } from '../../util/TeamUtils';

const DEFAULT_EXPIRATION_DAYS = 7;
const INCOMPLETE_TRADE_STATUSES = new Set(['DRAFT', 'PENDING'])

function TradeReview() {
  const navigate = useNavigate();
  const {leagueId, tradeId} = useParams();
  
  // Redux store variables
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const [trade, setTrade] = React.useState(null);
  const [players, setPlayers] = React.useState<TradeProposalPlayer[]>([]);
  const [canceling, setCanceling] = React.useState(false);
  const [withdrawing, setWithdrawing] = React.useState(false);
  const [rejecting, setRejecting] = React.useState(false);
  const [nonCompliantModal, setNonCompliantModal] = React.useState(false);
  const [accepting, setAccepting] = React.useState(false);
  const [allInvolvedTeamIds, setAllInvolvedTeamIds] = React.useState([]);
  const today = new Date();
  today.setMinutes(Math.ceil(today.getMinutes() / 15) * 15, 0, 0);
  const [expirationDateTime, setExpirationDateTime] = React.useState(new Date(today.getTime() + DEFAULT_EXPIRATION_DAYS * 24 * 60 * 60 * 1000));
  const [errors, setErrors] = React.useState([]);
  
  const [isTradeLoadComplete, setTradeLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: true, loadFullAuctionData: false,
                                                            loadRosterData: false, loadRosterFor: allInvolvedTeamIds,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  const myTeam = league?.teams ? teamMap.get(findDefaultTeam(league?.teams, user?.id!)) : null;
  
  const trades : Array<TradeProposal> = useLoadTradeData({teamId: myTeam?.id!, setIsException: setIsException, isLoadComplete: isTradeLoadComplete, setLoadComplete: setTradeLoadComplete});
  
  React.useEffect(() => {
    if (isTradeLoadComplete) {
      const thisTrade = trades.find(teamTrade => teamTrade.id === tradeId);
      if (thisTrade) {
        setTrade(thisTrade);
        setPlayers(thisTrade.players)
        if (thisTrade.expirationDateTime) {
          setExpirationDateTime(new Date(thisTrade.expirationDateTime));
        }
        setAllInvolvedTeamIds(thisTrade.teams.map(team => team.teamId));
      } else {
        setErrors(['Trade not found!']);
      }
    }
  }, [isTradeLoadComplete]);
  
  const allInvolvedTeams = allInvolvedTeamIds.map(teamId => teamMap.get(teamId));
  
  if (!isLoadComplete || !trade || !allInvolvedTeams.every(team => team?.rosterLoaded)) {
    return <p>Loading...</p>
  }
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const pickMap : Map<string | undefined, DraftPick | undefined> = new Map(league?.teams?.filter(team => allInvolvedTeamIds.includes(team.id)).flatMap(team => 
    team?.draftPicks?.map(pick => [pick.id, pick])
  ));
  const teamPlayerMap : Map<string | undefined, TeamPlayerInfo | undefined> = new Map(league?.teams?.filter(team => allInvolvedTeamIds.includes(team.id)).flatMap(team => 
    Object.values(team.playerRoster).flatMap(playerList => 
      playerList.filter(player => player).map(player => [player.playerId, player])
    )
  ));
  
  const proposingTeamId = trade.teams.find(team => team.status === 'PROPOSED').teamId;
  const proposingTeam = teamMap.get(proposingTeamId)!;
  const myProposal = proposingTeam.owners!.some(owner => owner.id === user.id);
  const myTradeStatus = trade.teams.find(team => team.teamId === myTeam.id).status;
  const currentSalary = getSalaryForSeason(myTeam!.paymentsBySeason![league.currentSeasonYear]);
  const rosterSize = getRosterSize(myTeam);
  const newRosterSize = trade.players.reduce((partialSum, tradePlayer) => {
        const owningTeam = teamMap.get(tradePlayer.fromTeamId);
        const isIr = owningTeam?.playerRoster![RosterPositionEnum.Ir].some(player => player && player.playerId === tradePlayer.playerId);
        return partialSum + (tradePlayer.toTeamId === myTeam?.id && tradePlayer.action !== 'MOVE_TO_IR' && (!isIr || tradePlayer.removeFromIr) ? 1 : (tradePlayer.fromTeamId === myTeam?.id && !isIr ? -1 : 0));
      }, rosterSize);
  const maxRosterSize = getMaxTeamSize(league!);
  const newEmptyRosterSpots = maxRosterSize - newRosterSize;
  const overRosterLimit = newEmptyRosterSpots < 0;
  const newSalary = getNewSalary(myTeam!, teamPlayerMap, pickMap, currentSalary, trade.players, trade.picks, league.currentSeasonYear, 0, newEmptyRosterSpots, league?.currentSeason)
  const overCap = newSalary > league.currentSeason.nflSeason.salaryCap;
  let errorInfo = Object.entries(errors).map(([key, value]) => {
    if (value) {
      return (<p className='error' key={'error_' + key}>{value}</p>);
    }
    return (<p className='error' key={'error_' + key}></p>);
  });
  
  function editTrade() {
    navigate("/league/" + league?.id + "/trade/" + tradeId + "/edit");
  }
  
  function cancelTrade() {
    tradeApi.cancelTradeProposal(tradeId).then(() => navigate("/league/" + league?.id));
  }
  
  function withdrawTrade() {
    tradeApi.withdrawTradeProposal(tradeId).then(() => navigate("/league/" + league?.id));
  }
  
  function submitTrade() {
    tradeApi.submitTradeProposal(expirationDateTime, tradeId).then(() => navigate("/league/" + league?.id));
  }
  
  function rejectTrade() {
    tradeApi.rejectTradeProposal(tradeId, myTeam?.id).then(() => navigate("/league/" + league?.id));
  }
  
  function checkForAcceptance() {
    if (overCap || overRosterLimit) {
      setNonCompliantModal(true);
    } else {
      setAccepting(true);
    }
  }
  
  function acceptTrade() {
    const payload: TradeAcceptancePayload = {
      dropIds: [],
      returnFromIrIds: players.filter(player => player.toTeamId === myTeam?.id && player.removeFromIr).map(player => player.playerId!)
    }
    tradeApi.acceptTradeProposal(tradeId, myTeam?.id, payload)
        .then(() => navigate("/league/" + league?.id))
        .catch(error => {
          setErrors([error.response.data.message]);
          setAccepting(false);
        });
  }
  
  function undoAcceptance() {
    tradeApi.undoAcceptance(tradeId, myTeam?.id).then(() => {
      const newTrade = {...trade};
      newTrade.teams.find(team => team.teamId === myTeam?.id).status = 'PENDING';
      setTrade(newTrade);
    });
  }
  
  function getActionOptions() {
    if (trade?.status === 'DRAFT') {
      return <Box>
        <Button color="secondary" variant="contained" onClick={editTrade}>Edit</Button>
        <Button color="secondary" variant="contained" onClick={() => setCanceling(true)}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={submitTrade}>Submit</Button>
      </Box>;
    }
    if (myProposal) { 
      return <Button color="primary" variant="contained" onClick={() => setWithdrawing(true)}>Withdraw Offer</Button>;
    }
    if (myTradeStatus === 'ACCEPTED') {
      return <Button color="primary" variant="contained" onClick={undoAcceptance}>Undo Acceptance</Button>;
    }
    return <Box>
      <Button color="secondary" variant="contained" onClick={() => setRejecting(true)}>Reject Proposal</Button>
      <Button color="secondary" variant="contained" onClick={() => navigate("/league/" + league?.id + "/trade/" + tradeId + "/counter")}>Counter Proposal</Button>
      <Button color="secondary" variant="contained" onClick={() => navigate("/league/" + league?.id + "/trade/" + tradeId + "/drop?returnFromIrIds=" + JSON.stringify(players.filter(player => player.toTeamId === myTeam?.id && player.removeFromIr).map(player => player.playerId!)))}>Select Players for Conditional Drop</Button>
      <br/>
      <Button color="primary" variant="contained" onClick={checkForAcceptance}>Accept Proposal</Button>
    </Box>  
  }
  
  const actionOptions = getActionOptions();
  
  function getConfirmationProperties() {
    if (canceling) {
      return {
        title: "Cancel Trade Proposal?",
        content: "Are you sure you want to cancel and delete this trade proposal?",
        confirmFn: cancelTrade
      }
    }
    if (withdrawing) {
      return {
        title: "Withdraw Trade Proposal?",
        content: "Are you sure you want to withdraw this trade proposal?",
        confirmFn: withdrawTrade
      }
    }
    if (rejecting) {
      return {
        title: "Reject Trade Proposal?",
        content: "Are you sure you want to reject this trade proposal?",
        confirmFn: rejectTrade
      }
    }
    if (accepting) {
      return {
        title: "Accept Trade Proposal?",
        content: "Are you sure you want to accept this trade proposal?",
        confirmFn: acceptTrade
      }
    }
    return {};
  }
  
  const confirmationProperties = getConfirmationProperties();
  
  return <Box>
    <Dialog open={canceling || withdrawing || rejecting || accepting} maxWidth="sm" fullWidth>
      <DialogTitle>{confirmationProperties.title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => {
            setCanceling(false);
            setWithdrawing(false);
            setRejecting(false);
            setAccepting(false);
          }}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>{confirmationProperties.content}</Typography>
      </DialogContent>  
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => {
            setCanceling(false);
            setWithdrawing(false);
            setRejecting(false);
            setAccepting(false);
        }}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={confirmationProperties.confirmFn}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={nonCompliantModal} maxWidth="sm" fullWidth>
      <DialogTitle>Resolve Violations</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setNonCompliantModal(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>
          This trade would put your team over the roster size and/or salary cap limits.  
          You can fix this by either conditionally dropping players as a part of this trade or 
          countering the offer with new terms.
        </Typography>
      </DialogContent>  
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setNonCompliantModal(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={() => navigate("/league/" + league?.id + "/trade/" + tradeId + "/drop?returnFromIrIds=" + JSON.stringify(players.filter(player => player.toTeamId === myTeam?.id && player.removeFromIr).map(player => player.playerId!)))}>
          Choose players to drop
        </Button>
        <Button color="secondary" variant="contained" onClick={() => navigate("/league/" + league?.id + "/trade/" + tradeId + "/counter")}>
          Counter Proposal
        </Button>
      </DialogActions>
    </Dialog>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Trade Proposal (Draft)
    </Typography>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      At a Glance
    </Typography>
    <TradeAtAGlance leagueId={leagueId} tradeStatus={trade.status} teams={trade.teams} picks={trade.picks} players={trade.players}
        playerMap={playerMap} teamMap={teamMap} pickMap={pickMap} />
    <br />
    <ReturnFromIrTradeOption players={players} allInvolvedTeams={allInvolvedTeams} setPlayers={setPlayers} playerMap={playerMap} myTeam={myTeam} />
    {INCOMPLETE_TRADE_STATUSES.has(trade.status) && <Box>
      <LeagueEventDatePicker eventDateTime={expirationDateTime} handleEventChange={event => setExpirationDateTime(event.target.value)} editMode={trade.status === 'DRAFT'} label="Offer Expiration Date/Time" />
      <br />
      {errorInfo}
      {actionOptions}
      <br /><br />
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Full Details
      </Typography>
      <TradeDetails leagueId={leagueId!} teams={allInvolvedTeams} currentSeason={league?.currentSeasonYear} players={trade.players} picks={trade.picks} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={pickMap} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
    </Box>}
  </Box>
}

export default TradeReview;
