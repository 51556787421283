import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {Box} from '@mui/material';
import { EventActivity, Team } from '../../../sdk/model';
import { Button } from '@mui/material';

interface tradeAssetDisplayProps {
  asset: EventActivity,
  teamMap: Map<string | undefined, Team>
}

function TradeAssetDisplay(props: tradeAssetDisplayProps) {
  const navigate = useNavigate();
  
  if (props.asset.action === 'DROP') {
    return <Box>
      Dropped as part of a <Button onClick={() => navigate('/league/' + props.asset.leagueId + '/tradeHistory/' + props.asset.leagueEventId)}>Trade</Button>
    </Box>
  }
  
  return <Box>
    <Button onClick={() => navigate('/league/' + props.asset.leagueId + '/tradeHistory/' + props.asset.leagueEventId)}>Traded</Button>
    from {props.teamMap.get(props.asset.fromTeamId)!.name} to {props.teamMap.get(props.asset.teamId)!.name} {props.asset.removeFromIr && " and returned from IR"}
  </Box>
}

export default TradeAssetDisplay;
