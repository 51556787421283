import * as React from 'react';
import {Box, TextField, InputLabel, Checkbox} from '@mui/material';

interface rosterConfigProps {
  rosterSettings: { [key: string]: number; }
  handleRosterChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
}

function RosterConfig(props: rosterConfigProps) {
  const [idp, setIdp] = React.useState(false);
  
  return <Box>
    <TextField name="QB" label="Quarterbacks (QB):" value={props.rosterSettings.QB} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <TextField name="RB" label="Running Backs (RB):" value={props.rosterSettings.RB} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <TextField name="WR" label="Wide Receivers (WR):" value={props.rosterSettings.WR} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <br/>
    <TextField name="TE" label="Tight Ends (TE):" value={props.rosterSettings.TE} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <TextField name="FLEX" label="Flex - RB/WR/TE (FLEX):" value={props.rosterSettings.FLEX} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <TextField name="DEF" label="Defenses (DEF):" value={props.rosterSettings.DEF} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <br/>
    <TextField name="K" label="Kickers (K):" value={props.rosterSettings.K} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <TextField name="SFLEX" label="Superflex - QB/RB/WR/TE (SFLEX):" value={props.rosterSettings.SFLEX} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <TextField name="P" label="Punters (P):" value={props.rosterSettings.P} type="number" 
        InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <br/>
    <InputLabel id="idpLabel">IDP (Individual Defensive Players)</InputLabel>
    <Checkbox checked={idp} labelId="idpLabel" onChange={() => setIdp(!idp)}/>
    <br/>
    {idp ?
      <Box>
        <TextField name="DL" label="Defensive Linemen (DL):" value={props.rosterSettings.DL} type="number" 
            InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
        <TextField name="LB" label="Linebackers (LB):" value={props.rosterSettings.LB} type="number" 
            InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
        <TextField name="DB" label="Defensive Backs (DB):" value={props.rosterSettings.DB} type="number" 
            InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
        <TextField name="DFLEX" label="Defensive Flex - DL/LB/DB (DFLEX):" value={props.rosterSettings.DFLEX} type="number" 
            InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
        <br/>
      </Box> : null
    }
    <TextField name="BN" label="Bench Spots (BN):" value={props.rosterSettings.BN} type="number" 
            InputProps={{inputProps: {min: 0, max: 50}}} onChange={props.handleRosterChange}/>
    <TextField name="IR" label="Injured Reserved Spots (IR):" value={props.rosterSettings.IR} type="number" 
            InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
            <TextField name="PS" label="Practice Squad Size (PS):" value={props.rosterSettings.PS} type="number" 
            InputProps={{inputProps: {min: 0, max: 10}}} onChange={props.handleRosterChange}/>
    <br/>
  </Box>
}

export default RosterConfig;
