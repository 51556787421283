import * as React from 'react'
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League, Player, Contract, Team, TradeProposalPlayer, TradeProposalPlayerActionEnum, PlayerPoachInfo } from '../../../sdk/model';
import {Box, Dialog, DialogTitle, IconButton, DialogContent, Typography, DialogActions, Button} from '@mui/material';
import Close from "@mui/icons-material/Close";
import TradeDetails from '../trade/TradeDetails';
import {isPlayerLocked} from '../../util/PlayerDataUtil';
import { getMaxTeamSize } from '../../util/TeamUtils';
import { contractApi } from '../../../adapters/APIExporter';
import { MINIMUM_ONE_YEAR_SALARY } from '../../util/ContractUtils';
import { toSalary } from '../../util/SalaryDisplay';

interface activatePlayerButtonProps {
  leagueId: string,
  player: Player,
  contract: Contract,
  currentSeasonYear: number,
  team: Team
}

function ActivatePlayerButton(props: activatePlayerButtonProps) {
  const [confirming, setConfirming] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const playerPoachInfo : Array<PlayerPoachInfo> | undefined = useAppSelector((state) => state.playerPoachDataLoad).get(props.team?.id);
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  if (!props.team) {
    return "";
  }
  const playerMap = new Map([[props.player?.id, props.player]]);
  const teamMap = new Map([[props.team.id, props.team]]);
  const teamPlayerMap = new Map(Object.values(props.team.practiceSquad!).map(player => [player.playerId, player]));
  const maxRosterSize = getMaxTeamSize(league!);
  const buttonDisabled = isPlayerLocked(props.player);
  const bid = playerPoachInfo?.find(playerPoach => props.player.id === playerPoach.playerId)?.topOffer || MINIMUM_ONE_YEAR_SALARY
  function activatePlayer() {
    contractApi.activateContract(props.contract.id, bid).then(response => {
      setConfirming(false);
    }).catch(error => {
      setErrors([error.response.data.message]);
    });
  }
  const players : TradeProposalPlayer[] = [{
    playerId: props.player.id,
    toTeamId: props.team.id,
    fromTeamId: props.team.id,
    action: TradeProposalPlayerActionEnum.Activate,
    newBidValue: bid
  }]
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  return <React.Fragment>
    <Dialog open={confirming} maxWidth="lg" fullWidth>
      <DialogTitle>Activate Player?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setConfirming(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        {errorInfo}
        <Typography>Are you sure you want to activate {props.player.abbr_name} from the Practice Squad for <b>1yr/{toSalary(bid)}</b>?  This will have the following impact on your team's salary cap:
          <TradeDetails leagueId={props.leagueId} teams={[props.team]} currentSeason={props.currentSeasonYear} players={players} picks={[]} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={new Map()} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setConfirming(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={activatePlayer}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Button sx={{marginLeft: 1}} color="primary" variant="contained" disabled={buttonDisabled} onClick={() => setConfirming(true)}>Activate Player</Button>
  </React.Fragment>
}

export default ActivatePlayerButton;
