import { NflPlayerGameStatistics, NflGame } from '../../sdk/model';
import {getNumberOrdinal} from './DraftPickDisplay'
import { Box, Typography } from '@mui/material';

const COMPLETED_GAME_STATUSES = new Set(['COMPLETE', 'CLOSED']);

const getGameTime = function(nflGame : NflGame) {
  if (COMPLETED_GAME_STATUSES.has(nflGame.status!)) {
    return 'Final';
  }
  if (nflGame.clock === '00:00') {
    if (nflGame.quarter === 2) {
      return 'HALF';
    }
    if (nflGame.quarter === 4) {
      return 'End of Reg'
    }
  } 
  return nflGame.clock + " " + (nflGame.quarter! > 4 ? 'OT' : getNumberOrdinal(nflGame.quarter!));
}

const playerMatchupDisplay = function(currentWeekStats: NflPlayerGameStatistics | undefined, nflGame: NflGame | undefined) {
  if (!currentWeekStats?.opponent) {
    return "BYE";
  }
  const opponentString = (currentWeekStats?.isHome ? "" : "@") + currentWeekStats?.opponent;
  if (nflGame && (nflGame.quarter || COMPLETED_GAME_STATUSES.has(nflGame.status!))) {
    const gameTime = getGameTime(nflGame);
    return <Box>
      <Typography>{opponentString} {gameTime}</Typography>
      <Typography>{nflGame.homePts} - {nflGame.awayPts}</Typography>
    </Box>;
  }
  const gametime = new Date(currentWeekStats?.startDateTime!);
  return opponentString + " " + gametime.toLocaleDateString(undefined, { weekday: 'short' }) + " " + gametime.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'});
}

export default playerMatchupDisplay;