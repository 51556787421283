import * as React from 'react';
import { Box } from '@mui/material';
import { toSalary } from '../../../util/SalaryDisplay';
import { YEAR_ONE_GUARANTEE_PERCENTAGE,
    FUTURE_YEAR_GUARANTEE_PERCENTAGE, MINIMUM_ONE_YEAR_SALARY, getPerSeasonSalaries } from '../../../util/ContractUtils';

interface contractSummaryProps {
  years: number,
  totalAmount: number,
  owningTeamName: string | undefined,
  currentSeason: number | undefined
}

function ContractSummary(props: contractSummaryProps) {
  function getGuaranteedSalary(years: number, totalAmount: number, perSeasonSalaries: Array<number>) {
    if (years === 1 && totalAmount === MINIMUM_ONE_YEAR_SALARY) {
      return 0;
    }
    return perSeasonSalaries.reduce((partialSum, salary, yearIndex) => {
      const guaranteePercentage = yearIndex === 0 ? YEAR_ONE_GUARANTEE_PERCENTAGE : FUTURE_YEAR_GUARANTEE_PERCENTAGE;
      return partialSum + (salary * guaranteePercentage);
    }, 0);
  }
  
  const apySalary = props.totalAmount / props.years;
  const perSeasonSalaries = getPerSeasonSalaries(props.years, props.totalAmount);
  const guaranteedSalary = getGuaranteedSalary(props.years, props.totalAmount, perSeasonSalaries);
  return <Box>
    <Box><b>{props.owningTeamName ? props.owningTeamName + " - " : ""}{props.years}yr/{toSalary(props.totalAmount)}</b>, {toSalary(perSeasonSalaries[0])} in {props.currentSeason}</Box>
    <Box>{toSalary(apySalary)} APY, {toSalary(guaranteedSalary)} Guar.</Box>
  </Box>
}

export default ContractSummary;
