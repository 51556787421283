import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League, ContractExtension } from '../../../sdk/model';
import { Box, Button, Select, MenuItem, Dialog, DialogTitle, IconButton, 
  DialogContent, DialogActions, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { getTotalContractValue } from '../../util/ContractUtils';
import { toSalary } from '../../util/SalaryDisplay';
import PlayerCardLink from '../../cards/playerCardLink';
import ContractSummary from '../auction/component/ContractSummary';
import Close from "@mui/icons-material/Close";
import { contractApi } from '../../../adapters/APIExporter';

const MAX_EXTENSIONS_PER_TEAM = 1;

function MidSeasonExtensionsView() {
  const {leagueId, teamId} = useParams();
  const navigate = useNavigate();
  
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  const [selectedOffer, setSelectedOffer] = React.useState<ContractExtension | undefined>(undefined);
  
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: true, 
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  const team = league?.teams.find(team => teamId === team.id);
  const myTeam = team?.owners?.some(owner => owner.id === user.id);
  const canExtend = myTeam && team?.currentSeason?.midSeasonExtensionsUsed !== undefined && team?.currentSeason?.midSeasonExtensionsUsed < MAX_EXTENSIONS_PER_TEAM;
  
  const columns = [
    {
      accessorKey: 'player.abbr_name',
      id: 'player',
      header: 'Player',
      minSize: 15,
      size: 15,
      Cell: ({ row }) => 
        <PlayerCardLink player={row.original.player} leagueId={leagueId!} />
    },
    {
      accessorKey: 'offersByLength.2',
      header: 'Two-Years',
      id: 'twoYears',
      size: 75,
      Cell: ({ renderedCellValue, row }) => 
        <Button className="capsized-button" disabled={!canExtend} onClick={() => setSelectedOffer({
            playerId: row.original.player.id,
            years: 2,
            totalValue: renderedCellValue})}>
          <ContractSummary years={2} totalAmount={renderedCellValue} owningTeamName={undefined} currentSeason={league?.currentSeason?.seasonYear} />
        </Button>
    },
    {
      accessorKey: 'offersByLength.3',
      header: 'Three-Years',
      id: 'threeYears',
      size: 75,
      Cell: ({ renderedCellValue, row }) => 
        <Button className="capsized-button" disabled={!canExtend} onClick={() => setSelectedOffer({
            playerId: row.original.player.id,
            years: 3,
            totalValue: renderedCellValue})}>
          <ContractSummary years={3} totalAmount={renderedCellValue} owningTeamName={undefined} currentSeason={league?.currentSeason?.seasonYear} />
        </Button>
    },
    {
      accessorKey: 'offersByLength.4',
      header: 'Four-Years',
      id: 'fourYears',
      size: 75,
      Cell: ({ renderedCellValue, row }) => 
        <Button className="capsized-button" disabled={!canExtend} onClick={() => setSelectedOffer({
            playerId: row.original.player.id,
            years: 4,
            totalValue: renderedCellValue})}>
          <ContractSummary years={4} totalAmount={renderedCellValue} owningTeamName={undefined} currentSeason={league?.currentSeason?.seasonYear} />
        </Button>
    },
  ]
  if (!league || !isLoadComplete || !team) {
    return "Loading data..."
  }
  const playersForExtension = Object.values(team!.playerRoster!).flatMap(playerList => playerList).filter(player => player && player.contract?.midseasonExtensionOfferValue);
  const playerInfoMap = new Map(playersForExtension.map(playerInfo => [playerInfo.playerId, playerInfo]));
  const data = playersForExtension.map(player => {
    return {
      player: playerMap.get(player.playerId),
      offersByLength: {
        2: getTotalContractValue(player.contract?.midseasonExtensionOfferValue!, 2),
        3: getTotalContractValue(player.contract?.midseasonExtensionOfferValue!, 3),
        4: getTotalContractValue(player.contract?.midseasonExtensionOfferValue!, 4)
      }
    }
  })
  function acceptExtension() {
    const playerInfo = playerInfoMap.get(selectedOffer!.playerId!)!;
    contractApi.extendContract(playerInfo.contract?.id, selectedOffer).then(() => 
      navigate('/league/' + league?.id)
    );
  }
  return <Box>
    <Dialog open={selectedOffer !== undefined} maxWidth="sm" fullWidth>
      <DialogTitle>Accept Contract Extension?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setSelectedOffer(undefined)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to extend 
          <PlayerCardLink leagueId={leagueId!} player={selectedOffer && playerMap.get(selectedOffer!.playerId!)} /> 
          for {selectedOffer?.years}yrs/{toSalary(selectedOffer?.totalValue!)}?  
          Each team may only extend 1 player per season.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setSelectedOffer(undefined)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={acceptExtension}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Select name="viewingTeam" label="Team" labelId="viewingTeamLabel" value={teamId} onChange={(event) => navigate('/league/' + league.id + '/team/' + event.target.value + '/midSeasonExtensions')}
      sx={{ flexGrow: 1 }}>
      {Object.entries(league?.teams!).map(([teamIndex, team]) => 
        <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
      )}
    </Select>
    <MaterialReactTable columns={columns} data={data} initialState={{
        sorting: [
          {
            id: "twoYears",
            desc: true,
          },
        ],
        
      }}></MaterialReactTable>
  </Box>
}

export default MidSeasonExtensionsView;
