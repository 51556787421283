import * as React from 'react'
import { useParams } from 'react-router-dom';
import { Team, Player, TradeProposalPlayer, PlayerPositionEnum, ContractStatus } from '../../../sdk/model';
import MaterialReactTable from 'material-react-table';
import TradeOptionsMenu from '../trade/TradeOptionsMenu';
import { getFinalSeasonOfContract, toSalary } from '../../util/SalaryDisplay';
import ContractDetails from '../contract/ContractDetails';
import {isPlayerLockedAt} from '../../util/PlayerDataUtil';
import LockIcon from '@mui/icons-material/Lock';
import PlayerCardLink from '../../cards/playerCardLink';

const positionOrder: Array<PlayerPositionEnum> = [PlayerPositionEnum.Qb,
                                                  PlayerPositionEnum.Rb,
                                                  PlayerPositionEnum.Wr,
                                                  PlayerPositionEnum.Te
                                                  ];

interface rosterContractDisplayProps {
  team: Team,
  myTeam: boolean | undefined,
  currentSeason: number,
  otherTeamIds: Array<string | undefined>,
  teamMap: Map<string | undefined, Team | undefined> | undefined,
  proposalPlayerMap: Map<string | undefined, TradeProposalPlayer | undefined> | undefined,
  playerMap: Map<string | undefined, Player | undefined>,
  handleTradeProposalChange: Function | undefined,
  earliestLockDate: Date | null,
  practiceSquad: boolean
}

function RosterContractDisplay(props: rosterContractDisplayProps) {
  const {leagueId} = useParams();
  const [otherTeamIds, setOtherTeamIds] = React.useState(props.otherTeamIds);
  React.useEffect(() => {
    if (props.otherTeamIds !== otherTeamIds) {
      setOtherTeamIds(props.otherTeamIds);
    }
  }, [props.otherTeamIds]);
  if (!props?.team.playerRoster) {
    return <p>Loading...</p>
  }
  const columns = [
      {
        accessorKey: 'position',
        header: 'Position',
        size: 5,
        sortingFn: (rowA, rowB) => {
          return positionOrder.indexOf(rowA.getValue('position')) - positionOrder.indexOf(rowB.getValue('position'));
          }
      },
      {
        accessorKey: 'name',
        header: 'Player Name',
        size: 50,
        Cell: ({cell, row}) => (
          <PlayerCardLink player={row.original.player} leagueId={leagueId!} />
        )
      },
      {
        accessorKey: 'capHit',
        header: 'Cap Hit',
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '-'
        )
      },
      {
        accessorKey: 'salary',
        header: 'Current Year Salary',
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '-'
        )
      },
      {
        accessorKey: 'remaining',
        header: 'Remaining This Season',
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '$0'
        )
      },
      {
        accessorKey: 'guaranteedRemaining',
        header: 'Guaranteed Remaining This Season',
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '$0'
        )
      },
      {
        accessorKey: 'nonGuaranteedRemaining',
        header: 'Non-Guaranteed Remaining This Season',
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '$0'
        )
      },
      {
        accessorKey: 'finalYear',
        header: 'Final Year of Contract',
        size: 50,
      }
    ]
    
  const columnVisibility = {
    position : true,
    name: true,
    capHit: true,
    salary: false,
    remaining: false,
    guaranteedRemaining: false,
    nonGuaranteedRemaining: false,
    finalYear: true
  }
    
  if (props.proposalPlayerMap) {
    columns.push({
        accessorKey: 'player',
        header: 'Action',
        size: 50,
        enableSorting: false,
        Cell : ({ cell }) => {
          const player = cell.getValue();
          const proposalPlayer = props.proposalPlayerMap!.get(player.id);
          if (player.lockedOnRoster || (props.earliestLockDate && isPlayerLockedAt(player, props.earliestLockDate))) {
            return <LockIcon />
          }
          return <TradeOptionsMenu handleTradeProposalChange={(playerId: string, fromTeamId: string, action: string) => props.handleTradeProposalChange(playerId, fromTeamId, action, 'player')} 
              asset={player} teamId={props.team.id!} myTeam={props.myTeam} droppable={true} otherTeamIds={otherTeamIds} teamMap={props.teamMap} earliestLockDate={props.earliestLockDate}
              value={proposalPlayer ? (proposalPlayer.action === 'DROP' ? 'drop' : proposalPlayer.toTeamId) : 'default'} disabled={cell.row.original.contract.status === ContractStatus.Expired} />
        }
      })
  }
  
  const roster = props.practiceSquad ? props.team.practiceSquad : Object.values(props?.team.playerRoster!).flatMap(rosterPlayers => rosterPlayers);
  const data = roster?.filter(player => player).map(rosterPlayer => {
    const player: Player = props.playerMap.get(rosterPlayer.playerId)!;
    return {
      position: player.position,
      name: player.abbr_name,
      salary: rosterPlayer.contract?.seasons!.find(season => season.season_year === props.currentSeason)?.annual_salary || 0,
      capHit: rosterPlayer.contract?.seasons!.find(season => season.season_year === props.currentSeason)?.weeks?.reduce((seasonSum, week) => 
        seasonSum + (week.payments?.filter(payment => props.team?.id === payment.teamId)?.reduce((weeklySum, payment) => weeklySum + (payment?.price || 0), 0) || 0)
      , 0) || 0,
      remaining: rosterPlayer.contract?.seasons!.find(season => season.season_year === props.currentSeason)?.weeks?.reduce((seasonSum, week) => 
        seasonSum + (week.payments?.filter(payment => !payment.completed)?.reduce((weeklySum, payment) => weeklySum + (payment?.price || 0), 0) || 0)
      , 0) || 0,
      guaranteedRemaining: rosterPlayer.contract?.seasons!.find(season => season.season_year === props.currentSeason)?.weeks?.reduce((seasonSum, week) => 
        seasonSum + Math.max(week.guaranteedSalary! - (week.payments?.filter(payment => payment.completed)?.reduce((weeklySum, payment) => weeklySum + (payment?.price || 0), 0) || 0), 0)
      , 0) || 0,
      nonGuaranteedRemaining: rosterPlayer.contract?.seasons!.find(season => season.season_year === props.currentSeason)?.weeks?.reduce((seasonSum, week) => 
        seasonSum + Math.max(week.fullSalary! - week.guaranteedSalary! - (week.payments?.filter(payment => payment.completed)?.reduce((weeklySum, payment) => weeklySum + (payment?.price || 0), 0) || 0), 0)
      , 0) || 0,
      finalYear: getFinalSeasonOfContract(rosterPlayer),
      player: player,
      contract: rosterPlayer.contract
    }
  });
  
  return <MaterialReactTable columns={columns} data={data} enableFilters={false} enableColumnActions={false} enablePagination={false} initialState={{
        density: 'compact',
        sorting: [
          {
            id: 'position',
            desc: false
          },
          {
            id: 'salary',
            desc: true
          }
        ],
        columnVisibility: columnVisibility
      }} renderDetailPanel={({row}) =><ContractDetails leagueId={leagueId!} player={row.original.player} contract={row.original.contract!} />} />;
}

export default RosterContractDisplay;