import * as React from 'react';
import { useAppSelector } from '../../app/hooks';
import { useLoadLeagueData } from '../../app/dataLoaderHooks';
import { findDefaultTeam } from '../body/draft/service';
import { styled } from '@mui/material/styles';
import { League, Team, Player, ContractStatus, GlobalConfig } from '../../sdk/model';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GameLogComponent from './component/gameLog';
import * as RandomString from "randomstring"
import { contractApi, nflGameApi } from '../../adapters/APIExporter';
import ContractDetails from '../body/contract/ContractDetails';
import { isPreSeason } from '../util/LeagueUtils';
import BidOnPlayerButton from '../body/playerClaims/BidOnPlayerButton';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface playerInfoCardProps {
  player: Player,
  leagueId: string | undefined
}

const CURRENT_CONTRACT_STATUSES = new Set([ContractStatus.Active, ContractStatus.Waived]);

export default function PlayerInfoCard(props: playerInfoCardProps) {
  const [expanded, setExpanded] = React.useState(false);
  const [contracts, setContracts] = React.useState([]);
  const [gameLog, setGameLog] = React.useState([]);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  const user = useAppSelector((state) => state.user);
  const globalConfig: GlobalConfig =  useAppSelector((state) => state.globalConfigDataLoad)

  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, 
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
                                                            
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));         
  const myDefaultTeam = league?.teams ? teamMap.get(findDefaultTeam(league?.teams, user?.id)) : null;


  const seasonToStat = isPreSeason(league?.phase) ? (globalConfig.currentSeason ?? 0) - 1 : (globalConfig.currentSeason ?? 0);
  
  React.useEffect(() => {
    if (props.player) {
      contractApi.getPlayerContracts(props.leagueId, props.player?.id).then(response => setContracts(response.data));
      nflGameApi.getGameLog(props.player?.id, seasonToStat).then(response => setGameLog(response.data));
    }
  }, [props.player])
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const playerName = props.player?.first_name + " " + props.player?.last_name;
  const subtitle = <div><b>Team:</b> {props.player?.nfl_team?.alias} <br/> <b>Position:</b> {props.player?.position}</div>

  const activeContract = contracts.find(contract => CURRENT_CONTRACT_STATUSES.has(contract.status));
  const previousContracts = contracts.filter(contract => !CURRENT_CONTRACT_STATUSES.has(contract.status));
  return (
    <Card style={{maxHeight: 800, overflow: 'auto'}}>
      <CardHeader
        avatar={
          <Avatar sx={{height: 120, width: 120}} src={props.player.picture}>
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={playerName}
        subheader={subtitle}
      />
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <GameLogComponent gameLog={gameLog} player={props.player} season={seasonToStat} scoringSettings={league?.currentSeason?.scoringSettings}/>
          <br/>
          <Typography  variant='h4'>Contract Details</Typography>
          {activeContract ? <Box>
            <Typography  variant='h5'>Current Contract:</Typography>
            <ContractDetails leagueId={props.leagueId!} player={props.player} contract={activeContract} />
          </Box> : <p>
            Free Agent
            <BidOnPlayerButton leagueId={props.leagueId} player={props.player} teamId={myDefaultTeam?.id!} />
           </p>}
          {previousContracts.length ? <Box>
            <Typography  variant='h5'>Previous Contracts:</Typography>
            {previousContracts.map(previousContract => <ContractDetails leagueId={props.leagueId!} player={props.player} contract={previousContract} />)}
          </Box> : <p>No Previous Contracts</p>}
        </CardContent>
      </Collapse>
    </Card>
  );
}