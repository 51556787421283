import * as React from 'react';
import { League, Player } from '../../../sdk/model';
import {Button} from '@mui/material';
import {isPlayerGameToday} from '../../util/PlayerDataUtil';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { useValidateAction } from '../../util/ActionValidationUtil';

interface bidOnPlayerButtonProps {
  leagueId : string,
  player : Player,
  teamId : string
}

function BidOnPlayerButton(props: bidOnPlayerButtonProps) {
  const navigate = useNavigate();
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                              loadFullDraftData: false, loadContractData: false,
                                                              loadBasicAuctionData: false, loadFullAuctionData: false,
                                                              loadRosterData: false, 
                                                              isException: isException, setIsException: setIsException,
                                                              isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const canBid = useValidateAction("signFreeAgents", league);
  
  return isPlayerGameToday(props.player) ? 
  	<Button color="primary" variant="contained" sx={{marginLeft: 2}} disabled={!canBid} onClick={() => navigate('/league/' + props.leagueId + '/team/' + props.teamId + '/quickSign/' + props.player.id)}>Add</Button> : 
    <Button color="primary" variant="contained" sx={{marginLeft: 2}} disabled={!canBid} onClick={() => navigate('/league/' + props.leagueId + '/team/' + props.teamId + '/faab/' + props.player.id)}>Bid</Button>;
}

export default BidOnPlayerButton;
