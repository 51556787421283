import * as React from 'react';
import { IrTransaction, Player, Team } from '../../../sdk/model';
import {Typography} from '@mui/material';
import TeamDisplay from '../team/TeamDisplay';
import PlayerCardLink from '../../cards/playerCardLink';

interface irTransactionDisplayProps {
  event: IrTransaction,
  playerMap: Map<String | undefined, Player | undefined>,
  teamMap: Map<String | undefined, Team>
}

function irTransactionDisplay(props: irTransactionDisplayProps) {
  return <Typography>
    <TeamDisplay leagueId={props.event.leagueId} team={props.teamMap.get(props.event.teamId)!} emphasized={false} />
    {props.event.toIrPlayerId === undefined || <React.Fragment>
      Sent <PlayerCardLink player={props.playerMap.get(props.event.toIrPlayerId)} leagueId={props.event.leagueId} /> to IR {props.event.fromIrPlayerId === undefined || "and "}
    </React.Fragment>}
    {props.event.fromIrPlayerId === undefined || <React.Fragment>
      Returned <PlayerCardLink player={props.playerMap.get(props.event.fromIrPlayerId)} leagueId={props.event.leagueId} /> from IR
    </React.Fragment>}
  </Typography>
}

export default irTransactionDisplay;
