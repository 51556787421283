import * as React from 'react';
import { ContractExtension, Player, Team } from '../../../sdk/model';
import {Typography} from '@mui/material';
import TeamDisplay from '../team/TeamDisplay';
import PlayerCardLink from '../../cards/playerCardLink';
import {toSalary} from '../../util/SalaryDisplay';

interface midSeasonExtensionDisplayProps {
  leagueId: string,
  teamId: string,
  details: ContractExtension,
  playerMap: Map<String | undefined, Player | undefined> | undefined,
  teamMap: Map<String | undefined, Team>
}

function MidSeasonExtensionDisplay(props: midSeasonExtensionDisplayProps) {
  const team = props.teamMap.get(props.teamId)!;
  const player = props.playerMap ? props.playerMap.get(props.details.playerId) : undefined;
  return <Typography>
    <TeamDisplay leagueId={props.leagueId} team={team} emphasized={false} />
    extended {player !== undefined && <PlayerCardLink player={player} leagueId={props.leagueId} />}
    for {props.details.years}yrs/{toSalary(props.details.totalValue)}
  </Typography>
}

export default MidSeasonExtensionDisplay;
